// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maxLength_container__tjoUY {
  position: absolute;
  top: -6px;
  right: 5px;
  font-size: var(--font-size-small);
  color: var(--field-max-length-color);
  padding: 0 4px;
  line-height: 12px;
  background: var(--field-max-length-background);
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/addons/MaxLength/maxLength.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACH,iCAAA;EACG,oCAAA;EACA,cAAA;EACA,iBAAA;EACA,8CAAA;EACA,kBAAA;AACJ","sourcesContent":[".container {\n    position: absolute;\n    top: -6px;\n    right: 5px;\n\tfont-size: var(--font-size-small);\n    color: var(--field-max-length-color);\n    padding: 0 4px;\n    line-height: 12px;\n    background: var(--field-max-length-background);\n    border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `maxLength_container__tjoUY`
};
export default ___CSS_LOADER_EXPORT___;
