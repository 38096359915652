import { InsertAttachmentRequest } from 'components/Form';

export class CustomContractsModel {
	id!: number;
	name!: string;
	listOfContracts?: InsertAttachmentRequest[] | undefined;

	constructor(data?: CustomContractsModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
