import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { BlockscoutModel } from './blockscoutFormModel';
import { BlockscountForm } from './BlockscountForm';
import {BlockscoutSubRoutesEnum, StepSubRoutesEnum } from '../routes';
import { useDispatch } from 'react-redux';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { networkRoute } from 'containers/Network/routes';
import { myNetworkRoute } from 'containers/MyNetwork/routes';
import notifications from 'components/Notification/notification';
import { ConfigurationModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel';

type ParamType = {
	networkId: string
}

export const Blockscount = () => {
	const { networkId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newConfigBlockscout: BlockscoutModel) => {
			if (!network) {
				return;
			}

			const configurationModel = new ConfigurationModel({
				...network.configuration,
				loadBalancers: [...network.configuration?.loadBalancers!,
					{id: 2, name: 'public-explorer-lb', loadBalancersTypeId: 1},
					{id: 3, name: 'private-explorer-lb', loadBalancersTypeId: 1},
				],
			});

			const networkModel = new NetworkModel({
				...network,
				configuration: network.configuration?.loadBalancers?.length === 1 ? configurationModel : network.configuration,
				blockscout: newConfigBlockscout,
				isBlockExplorersInitialized: true
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				notifications.info('Now, we created two Load Balancers for you: public-explorer-lb and private-explorer-lb!.');
				notifications.info('🏁 Everything looks amazing! You are ready to deploy blockchain 🏎️.');
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[network, history, dispatch]
	);

	const advancedConfigurationClickCallback = useCallback(
		() => {
			history.push(`${history.location.pathname}/${BlockscoutSubRoutesEnum.ADVANCED_CONFIGURATION}/${StepSubRoutesEnum.STEP_ONE}`);
		},
		[history]
	);

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Blockscout' subtitle='Configuration' />
			<BlockscountForm
				configBlockscout={network?.blockscout}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
				onAdvancedConfigurationClick={advancedConfigurationClickCallback}
			/>
		</ColumnContainer>
	)
}

