import { AdditionalAccountsIcons } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const additionalAccountsRoute: Route = {
	id: RootRouteEnum.ADDITIONAL_ACCOUNTS,
	url: RootRouteEnum.ADDITIONAL_ACCOUNTS,
	text: 'Additional Accounts',
	Icon: AdditionalAccountsIcons,
}