import { getAccessTokenAction } from 'actions/AuthActions';
import { toast } from 'react-toastify';
import { BaseResponseModel, ApiException } from 'services/bladeApiService';

export const globalErrorKey = '';
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

export const defaultErrorResponse = new BaseResponseModel({
    success: false,
    errors: {}
})

const fixErrorResponse = (result: any) => ({ ...result, success: false })

export const catchError = <P extends BaseResponseModel>(error: any, preventNotification?: boolean) => {
    // server error
    if (error.status === 500) {
        !preventNotification && toast.error('Unknown server error, please contact system administrator');
        return defaultErrorResponse as P;
    }

    // validation error
    if (error.status === 400) {
        const result = (error as ApiException).result;
        const errors = (result as BaseResponseModel)?.errors;
        const message = errors && errors[globalErrorKey] && errors[globalErrorKey].message;
        !preventNotification && toast.error(message);
        // error.result is BaseResponseModel all the time (if backend is correctly implemented)
        return fixErrorResponse(result);
    }

    // forbidden, there is no permission for this user or action is not possible (example: cannot delete entity that is used)
    if (error.status === 403) {
        // error.result is BaseResponseModel all the time (if backend is correctly implemented)
        const result = (error as ApiException).result;
        const errors = (result as BaseResponseModel)?.errors;
        const message = errors && errors[globalErrorKey] && errors[globalErrorKey].message;
        !preventNotification && toast.error(message);
        return fixErrorResponse(result);
    }

    // unauthorized error
    if (error.status === 401) {
        !preventNotification && toast.error('You are unauthorized for this action. Please login to application and try this action again. If problem remains, please contact system administrator.');
        return defaultErrorResponse as P;
    }

    if (error instanceof TypeError) {
        // INFO: this is not useful to user, it is more for us to see the message
        !preventNotification && toast.error(error.toString());
        return defaultErrorResponse as P;
    }

    !preventNotification && toast.error('Unknown error, please contact system administrator');
    return defaultErrorResponse as P;
}

export const tryCatchJsonByAction = async <P extends BaseResponseModel>(fetchFunction: (...args: any[]) => Promise<P>, preventNotification?: boolean): Promise<P> => {
    return await tryCatchJsonByActionInner(fetchFunction, true, preventNotification);
}

const tryCatchJsonByActionInner = async <P extends BaseResponseModel>(fetchFunction: (...args: any[]) => Promise<P>, tryRefreshAccessToken: boolean, preventNotification?: boolean): Promise<P> => {
    try {
        const response = await fetchFunction();
        return response || defaultErrorResponse as P;
    }
    catch (error: any) {
        // when access token expires, try to refresh the access token, and then execute the action again
        if (tryRefreshAccessToken && error.status === 401 && error.headers && error.headers['token-expired']) {
            const refreshToken = getRefreshToken();
            if (refreshToken) {
                try {
                    removeAccessToken();
                    const accessTokenResponse = await getAccessTokenAction(refreshToken);
                    if (accessTokenResponse.success && accessTokenResponse.value?.token) {
                        setAccessToken(accessTokenResponse.value.token);
                        return await tryCatchJsonByActionInner(fetchFunction, false);
                    }
                    else {
                        removeRefreshToken();
                    }
                }
                catch { }
            }
        }

        return catchError<P>(error, preventNotification);
    }
}

// INFO: form should export this type
// delta errors has array, not string. Check this "any" type
type FormErrorsType = {
    [key: string]: string | any
}

// INFO: this should be called somewhere in tryCatchJson... and all calls to this method should be removed
export const convertResponseErrors = (response: BaseResponseModel): FormErrorsType | undefined => {
    if (response.errors) {
        const errors: any = {};
        for (const key of Object.keys(response.errors)) {
            // INFO: additionalInfo and messageCode should be implemented with localization
            // TODO: delta errors are stored in additionalInfo. Check can we use that property for delta errors
            key.split('.').reduce(
                (accumulator: any, currentSubKey: any, index: number, array: string[]) => {
                    let arrayRegex = /^[a-zA-Z]+[[0-9]*]/g;
                    let newObj: any;

                    // check is property name array "insert[0]"
                    if (arrayRegex.test(currentSubKey)) {
                        const splited = currentSubKey.split('[');
                        const arrayKey = splited[0];
                        const arrayIndex = parseInt(splited[1].slice(0, -1));

                        //create array first, then create object
                        newObj = accumulator[arrayKey] || [];
                        accumulator[arrayKey] = newObj;

                        newObj = accumulator[arrayKey][arrayIndex] || {};

                        // if last, set message
                        if (index === array.length - 1) {
                            newObj = response.errors![key].message;
                        }
                        // set accumulator to new object
                        accumulator[arrayKey][arrayIndex] = newObj;
                    } else { // property name is object
                        newObj = accumulator[currentSubKey] || {};
                        if (index === array.length - 1) {
                            // if last, set message
                            newObj = response.errors![key].message;
                        }
                        // set accumulator to new object
                        accumulator[currentSubKey] = newObj;
                    }

                    return newObj;
                },
                errors
            );
        }
        return errors;
    }
}

export const setAccessToken = (accessToken: string) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export const setRefreshToken = (refreshToken: string) => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
}

export const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
}

export const removeAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}

export const removeRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
}
