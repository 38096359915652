import { useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { NetworkSubRoutesEnum } from '../routes';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { NetworkForm } from './NetworkForm';
import { NetworkDetails } from './NetworkDetails';
import { DetailsModel } from './networkFormModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { createNetworkAction, saveNetworkConfigurationAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { useDispatch } from 'react-redux';

export const CreateNetwork = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (values: DetailsModel) => {
			const networkModel = new NetworkModel({
				details: values,
				isInitialized: false,
				isPolygonEdgeInitialized: false,
				isBootstrapAndServiceInitialized: false,
				isBlockExplorersInitialized: false,
				isDeploy: false
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				networkName: values.name,
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				const newNetworkModel = new NetworkModel({
					...networkModel,
					id: response.value.id,
				});

				const newRequestModel = new SaveNetworkConfigurationRequest({
					...requestModel,
					id: response.value?.id,
					networkConfiguration: JSON.stringify(newNetworkModel)
				});

				const bindedAction = saveNetworkConfigurationAction.bind(null, newRequestModel);
				const newResponse = await tryCatchJsonByAction(bindedAction);

				if (newResponse.success && newResponse.value) {
					dispatch(createNetworkAction(newNetworkModel));
					history.push(`${path.split('/').slice(0, -1).join('/')}/${NetworkSubRoutesEnum.VIEW}`)
				}
			}
		},
		[history, path, dispatch]
	);

	const newNetwork = useMemo(
		() => {
			const model = new DetailsModel();
			model.cloudServiceId = 1;
			return model
		},
		[]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Enter Network Details' />
			<SmartContainer>
				<SmartItem size='small'>
					<NetworkDetails />
				</SmartItem>
				<SmartItem>
					<NetworkForm
						network={newNetwork}
						onSave={handleSaveCallback}
						cancel={handleGoBackCallback}
					/>
				</SmartItem>
			</SmartContainer>
		</ColumnContainer>
	)
}
