import { useCallback, useState } from 'react';
import { Form, InputField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { UserLoginRequest } from 'services/bladeApiService';
import { tryCatchJsonByAction, convertResponseErrors, setAccessToken, setRefreshToken } from 'utils/fetchUtils';
import { useHistory } from 'react-router-dom';
import { loginAction } from 'actions/AuthActions';
import { setUserDataSliceAction } from 'store/slices/userDataSlice';
import { useDispatch } from 'react-redux';
import { HOME_ROUTE, RedirectToLoginState } from 'pages/PageRouter';

class Model {
	email!: string;
	password!: string;
}

type Props = {
	state?: RedirectToLoginState | null
}

export const Login = ({ state = {} }: Props) => {
	const [values, setValues] = useState(new Model());
	const history = useHistory();
	const dispatch = useDispatch();

	const onSubmitCallback = useCallback(
		async () => {
			const newLoginRequestModel = new UserLoginRequest({
				email: values.email,
				password: values.password,
			});

			const bindedAction = loginAction.bind(null, newLoginRequestModel)
			const loginResponse = await tryCatchJsonByAction(bindedAction);

			if (loginResponse.success && loginResponse.value) {
				const { accessToken, refreshToken, user } = loginResponse.value;
				setRefreshToken(refreshToken.token);
				setAccessToken(accessToken.token);
				dispatch(setUserDataSliceAction(user));
				history.push(state?.from || HOME_ROUTE);
			} else {
				return convertResponseErrors(loginResponse);
			}

		},
		[values, history, state?.from, dispatch]
	);

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			submitButtonText='Login'
			hideCancelButton
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer margin='small'>
					<SmartContainer>
						<SmartItem>
							<InputField
								id='email'
								label='Email'
								multiline
								isRequired
								focus
							/>
							<InputField
								type='password'
								id='password'
								label='Password'
								multiline
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
};
