// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab_navigation__6sLbs {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  border-bottom: 1px solid var(--tab-separator-color);
}

.tab_tab__F9X7d {
  display: block;
  padding: 0.5rem 4px;
  margin-right: 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: var(--tab-color);
}
.tab_tab__F9X7d.tab_active__t3jMJ {
  border: none;
  color: var(--tab-active-color);
  box-shadow: unset;
  border-bottom: 2px solid var(--primary-color);
}
.tab_tab__F9X7d.tab_disabled__jJRGa {
  cursor: not-allowed;
  opacity: 0.55;
}
.tab_tab__F9X7d:hover:not(.tab_disabled__jJRGa) {
  color: var(--tab-active-color);
}

.tab_content__4pU7q {
  padding: 20px 24px;
  margin: 0px -24px;
  overflow-x: auto;
  overflow-y: hidden;
  display: initial;
}`, "",{"version":3,"sources":["webpack://./src/components/Tabs/tab.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACG,eAAA;EACA,eAAA;EACA,mDAAA;AACJ;;AAEA;EACI,cAAA;EACA,mBAAA;EACH,kBAAA;EACG,YAAA;EACA,eAAA;EACH,qBAAA;EACA,iBAAA;EACA,uBAAA;AACD;AACC;EACC,YAAA;EACA,8BAAA;EACA,iBAAA;EACA,6CAAA;AACF;AAEC;EACC,mBAAA;EACA,aAAA;AAAF;AAGC;EACC,8BAAA;AADF;;AAKA;EAEI,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACH,gBAAA;AAHD","sourcesContent":[".navigation {\n\tdisplay: flex;\n    flex-wrap: wrap;\n    padding-left: 0;\n    border-bottom: 1px solid var(--tab-separator-color);\n}\n\n.tab {\n    display: block;\n    padding: .5rem 4px;\n\tmargin-right: 16px;\n    border: none;\n    cursor: pointer;\n\ttext-decoration: none;\n\tfont-weight: bold;\n\tcolor: var(--tab-color);\n\n\t&.active {\n\t\tborder: none;\n\t\tcolor: var(--tab-active-color);\n\t\tbox-shadow: unset;\n\t\tborder-bottom: 2px solid var(--primary-color);\n\t}\n\n\t&.disabled {\n\t\tcursor: not-allowed;\n\t\topacity: .55;\n\t}\n\n\t&:hover:not(.disabled) {\n\t\tcolor: var(--tab-active-color);\n\t}\n}\n\n.content {\n\t// padding/margin is added because of SmartContainer, so scrollbar is not shown\n    padding: 20px 24px;\n    margin: 0px -24px;\n    overflow-x: auto;\n    overflow-y: hidden;\n\tdisplay: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `tab_navigation__6sLbs`,
	"tab": `tab_tab__F9X7d`,
	"active": `tab_active__t3jMJ`,
	"disabled": `tab_disabled__jJRGa`,
	"content": `tab_content__4pU7q`
};
export default ___CSS_LOADER_EXPORT___;
