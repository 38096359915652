// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlaySpinner_container__zJr8a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlaySpinner_background__D8-sQ {
  background: var(--with-fetch-background);
}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/overlaySpinner.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AACD;;AAEA;EACC,wCAAA;AACD","sourcesContent":[".container {\n\tposition: absolute;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n}\n\n.background {\n\tbackground: var(--with-fetch-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `overlaySpinner_container__zJr8a`,
	"background": `overlaySpinner_background__D8-sQ`
};
export default ___CSS_LOADER_EXPORT___;
