export class SimpleModel {
	// validator and fullNodes
	id!: number;
	name!: string;
	instanceClassId?: number | undefined;
	instanceTypeId?: number | undefined;
	stake?: number | undefined;
	stakeTypeId?: number | undefined;
	premine?: number | undefined;
	premineTypeId?: number | undefined;
	// loadBalancers
	loadBalancersTypeId?: number | undefined;
	loadBalancersValidators?: number[] | undefined
	loadBalancersFullNodes?: number[] | undefined
	// blockExplorers
	isBockscout?: boolean | undefined
	isEthernal?: boolean | undefined

	constructor(data?: SimpleModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}

export class ConfigurationModel {
	instanceClassId?: number | undefined;
	validators?: SimpleModel[] | undefined;
	validatorsNumber?: number | undefined;
	validatorsInstanceTypeId?: number | undefined;
	validatorsDeviceCapacity?: number | undefined;
	fullNodes?: SimpleModel[] | undefined;
	fullNodesNumber?: number | undefined;
	fullNodesInstanceTypeId?: number | undefined;
	fullNodesDeviceCapacity?: number | undefined;
	loadBalancers?: SimpleModel[] | undefined;

	constructor(data?: ConfigurationModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
