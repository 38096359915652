import { Form, InputField } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AccountModel } from './profileFormModel'


type Props = {
	profile?: AccountModel
	onSave: (newAccount: AccountModel) => void
	cancel(): void
}

export const ProfileForm = ({ profile, onSave, cancel }: Props) => {
	const [values, setValues] = useState(profile || new AccountModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Save'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartContainer>
								<SmartItem size='small'>
									<SmartFormGroup label='Personal Info'>
										<InputField
											id={propertyOf<AccountModel>('username')}
											label='Username'
											isRequired
										/>
										<InputField
											id={propertyOf<AccountModel>('email')}
											label='Email'
											isEmail
											isRequired
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='small'>
									<VerticalSeparator margin='large' />
									<InputField
										id={propertyOf<AccountModel>('company')}
										label='Company'
										isRequired
									/>
								</SmartItem>
							</SmartContainer>
							<VerticalSeparator margin='small' />
							<SmartContainer>
								<SmartItem size='small'>
									<SmartFormGroup label='AWS'>
										<InputField
											id={propertyOf<AccountModel>('awsKeyId')}
											label='Access Key ID'
											isRequired
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='small'>
									<VerticalSeparator margin='large' />
									<InputField
										id={propertyOf<AccountModel>('secretAccessAwsKey')}
										label='Secret Access Key'
										type='password'
										isRequired
									/>
								</SmartItem>
							</SmartContainer>
							<VerticalSeparator margin='small' />
							<SmartContainer>
								<SmartItem size='small'>
									<SmartFormGroup label='DataDog'>
										<InputField
											id={propertyOf<AccountModel>('dataDogKeyId')}
											label='API Key'
											isRequired
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='small'>
									<VerticalSeparator margin='large' />
									<InputField
										id={propertyOf<AccountModel>('dataDogApiKey')}
										label='APP Key'
										type='password'
										isRequired
									/>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
