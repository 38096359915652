export class PolygonEdgeModel {
	chainId!: number;
	nativeToken!: string;
	consensusProtocol?: number | undefined;
	version?: number | undefined;
	enableEip?: boolean | undefined;
	advancedConfiguration?: AdvancedConfigurationModel | undefined;

	constructor(data?: PolygonEdgeModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}

export class AdvancedConfigurationModel {
	directory!: string;
	location!: string;
	mountPoint!: string;
	partitionName!: string;
	deviceName!: string;
	deviceCapacity!: number | undefined;
	gRpcPort?: number | undefined;
	p2pPort?: number | undefined;
	jsonRpcPort?: number | undefined;
	additionalStorage?: boolean | undefined;

	constructor(data?: AdvancedConfigurationModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
