// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/network_Ilustration.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network_container__i3dPV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.network_img__APaec {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 600px;
  height: 445px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Network/network.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;EACC,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,YAAA;EACA,aAAA;AACD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.img {\n\tbackground-image: url('../../assets/images/network_Ilustration.png');\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\tbackground-position: center;\n\twidth: 600px;\n\theight: 445px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `network_container__i3dPV`,
	"img": `network_img__APaec`
};
export default ___CSS_LOADER_EXPORT___;
