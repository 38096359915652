import { Button } from 'components/Button'
import { useCallback, useMemo } from 'react'
import styles from './myNetwork.module.scss'
import { Item } from './Item'
import { useHistory } from 'react-router-dom'
import { validatorsRoute } from 'containers/Validators/routes'
import { fullNodesRoute } from 'containers/FullNodes/routes'
import { loadBalancersRoute } from 'containers/LoadBalancers/routes'
import { SimpleModel } from './ConfigurationMyNetwork/myNetworkFormModel'
import { blockExplorersRoute } from 'containers/BlockExplorers/routes'

type Props = {
	items: SimpleModel[] | undefined
	onClick?(id: string): void
	onEditSave?(values: SimpleModel): Promise<void>
	hideViewAllButton?: boolean
	isValidatorsOrFullNodes?: 'validator' | 'fullnode'
	disabled?: boolean
}

export const BoxItems = ({ items, onClick, onEditSave, hideViewAllButton, isValidatorsOrFullNodes, disabled }: Props) => {
	const history = useHistory();

	const goToPageMemo = useMemo(
		() => {
			const item = items?.find(x => x)?.name.split('-')[0];

			if (item && item === 'validator') {
				return validatorsRoute.url;
			} else if (item && item === 'fullnode') {
				return fullNodesRoute.url;
			} else if (item && items.find(x => x.isBockscout || x.isEthernal)) {
				return blockExplorersRoute.url;
			} else {
				return loadBalancersRoute.url;
			}
		},
		[items]
	)

	const viewAllClickCallback = useCallback(
		() => {
			history.push(goToPageMemo);
		},
		[history, goToPageMemo]
	)

	return (
		<div className={styles.content}>
			{items?.map((item) => (
				<Item
					key={item.id}
					item={item}
					onClick={onClick}
					onEditSave={onEditSave}
					isValidatorsOrFullNodes={isValidatorsOrFullNodes}
					disabled={disabled}
				/>
			))}
			{!hideViewAllButton &&
				<div className={styles.button}>
					<Button
						text='View All'
						color='neutral'
						onClick={viewAllClickCallback}
						disabled={disabled}
					/>
				</div>
			}
		</div>
	)
}
