import { useCallback, useMemo } from 'react';
import { PolygonEdgeModel } from './polygonEdgeFormModel';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { useHistory, useParams } from 'react-router-dom';
import { PolygonEdgeForm } from './PolygonEdgeForm';
import { PolygonEdgeSubRoutesEnum } from './routes';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { useDispatch } from 'react-redux';
import { myNetworkRoute } from 'containers/MyNetwork/routes';
import { networkRoute } from 'containers/Network/routes';
import notifications from 'components/Notification/notification';

type ParamType = {
	networkId: string
}

export const PolygonEdge = () => {
	const { networkId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newPolygonEdge: PolygonEdgeModel) => {
			if (!network) {
				return;
			}

			const networkModel = new NetworkModel({
				...network,
				polygonEdge: newPolygonEdge,
				isPolygonEdgeInitialized: true
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				notifications.info('Awesome 🤩 You have successfully configured Polygon Edge. Now, you must configure Bootstrap and Service to complete the Polygon Edge installation.');
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[network, history, dispatch]
	);

	const advancedConfigurationClickCallback = useCallback(
		() => {
			history.push(`${history.location.pathname}/${PolygonEdgeSubRoutesEnum.ADVANCED_CONFIGURATION}`);
		},
		[history]
	);

	const polygonEdgeMemo = useMemo(
		() => {
			if (network?.polygonEdge) {
				return network.polygonEdge;
			}

			const model = new PolygonEdgeModel();
			model.chainId = 900;
			model.nativeToken = 'Example:EXM:18:true';
			return model;
		},
		[network?.polygonEdge]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Polygon Edge' />
			<PolygonEdgeForm
				polygonEdge={polygonEdgeMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
				onAdvancedConfigurationClick={advancedConfigurationClickCallback}
			/>
		</ColumnContainer>
	)
}
