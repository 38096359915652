import { useLocation } from 'react-router-dom'
import { Route } from 'feature/Routes/routeModel'
import { Item } from './Item'
import styles from './menu.module.scss'
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum'

type Props = {
	items: Route[]
	networkId: string
}

export const Menu = ({ items, networkId }: Props) => {
	const { pathname } = useLocation();

	const menuItems = items.map((item) => {
		return (
			<Item
				key={item.id}
				route={item}
				networkId={networkId}
				isActive={pathname.includes(item.url) || pathname === '/'}
				isAccountItem={item.id === RootRouteEnum.PROFILE}
			/>
		)
	})

	return (
		<div className={styles.container}>
			{menuItems}
		</div>
	)
}
