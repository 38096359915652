import { AutoCompleteField, Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AdvancedConfigurationBlockscoutModel } from '../blockscoutFormModel';


const yesNoItems = [
	{id: true, name: 'Yes'},
	{id: false, name: 'No'},
]

type Props = {
	advancedConfiguration?: AdvancedConfigurationBlockscoutModel
	onSave: (newAdvancedConfiguration: AdvancedConfigurationBlockscoutModel) => void
	cancel(): void
}

export const AdvancedConfigurationBlockscoutForm = ({ advancedConfiguration, onSave, cancel, }: Props) => {
	const [values, setValues] = useState(advancedConfiguration || new AdvancedConfigurationBlockscoutModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Next'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='small'>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('databaseURL')}
								label='Database URL'
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('subNetwork')}
								label='Subnetwork'
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('coin')}
								label='Coin'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('chainId')}
								label='Chain Id'
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('heartCommand')}
								label='Heart command'
								isRequired
							/>
						</SmartItem>
						<SmartItem size='small'>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('secretKeyBase')}
								label='Secret Key Base'
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('coinName')}
								label='Coin Name'
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('isDisableBlockReward')}
								label='Indexer Disable Block Reward Fetcher'
								items={yesNoItems}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('isDisablePendingTransactions')}
								label='Indexer Disable Pending Transactions Fetcher'
								items={yesNoItems}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

