import { IconType } from "components/Icons/icons"
import { blockscoutBlockExplorersSubRoute, ethernalBlockExplorersSubRoute } from "../routes"
import { ChooseItem } from "./ChooseItem"

export type ChooseType = {
	id: string
	url: string
	text: string
	Icon?: IconType
}

const items: ChooseType[] = [
	{ id: '1', url: `${blockscoutBlockExplorersSubRoute.url}`, text: `${blockscoutBlockExplorersSubRoute.text}`, Icon: blockscoutBlockExplorersSubRoute.Icon },
	{ id: '2', url: `${ethernalBlockExplorersSubRoute.url}`, text: `${ethernalBlockExplorersSubRoute.text}`, Icon: ethernalBlockExplorersSubRoute.Icon },
]

type Props = {
	networkId: string
}

export const Choose = ({ networkId }: Props) => {
	return (
		<>
			{items.map((item) => (
				<ChooseItem
					key={item.id}
					item={item}
					networkId={networkId}
				/>
			))}
		</>
	)
}
