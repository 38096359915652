import { ColumnContainer } from 'components/Layout'
import { ContentTitle } from 'feature/Content/ContentTitle'
import { Choose } from './Choose/Choose'
import { useParams } from 'react-router-dom'

type ParamType = {
	networkId: string
}

export const BlockExplorers = () => {
	const { networkId }: ParamType = useParams();

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Block Explorers' subtitle='Choose' />
			<Choose networkId={networkId} />
		</ColumnContainer>
	)
}
