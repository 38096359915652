import { ConfigurationModel, SimpleModel } from "containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel";

export const getValidatorOrFullNodeItems = (networkConfiguration: ConfigurationModel, name: 'validator' | 'fullnode') => {
	const { validatorsNumber, fullNodesNumber, instanceClassId, validatorsInstanceTypeId, fullNodesInstanceTypeId } = networkConfiguration;

	const instanceTypeId = name === 'validator' ? validatorsInstanceTypeId : fullNodesInstanceTypeId;
	const lengthNumber = name === 'validator' ? validatorsNumber || 0 : fullNodesNumber || 0;

	const items: SimpleModel[] = [];
	for (let i = 1; i <= lengthNumber; i++) {
		items.push(new SimpleModel({
			id: i,
			name: `${name}-${i.toString().padStart(3, '0')}`,
			instanceClassId: instanceClassId,
			instanceTypeId: instanceTypeId
		}));
	}

	return items;
}

export const updateValidatorOrFullNodeItems = (initialConfiguration: ConfigurationModel, newConfiguration: ConfigurationModel, name: 'validator' | 'fullnode') => {
	const { validators, fullNodes, validatorsNumber, fullNodesNumber, instanceClassId, validatorsInstanceTypeId, fullNodesInstanceTypeId } = initialConfiguration;

	const initialNumber = name === 'validator' ? validatorsNumber || 0 : fullNodesNumber || 0;
	const newValue = name === 'validator' ? newConfiguration.validatorsNumber || 0 : newConfiguration.fullNodesNumber || 0;
	const initialInstanceTypeId = name === 'validator' ? validatorsInstanceTypeId : fullNodesInstanceTypeId;
	const newInstanceTypeId = name === 'validator' ? newConfiguration.validatorsInstanceTypeId : newConfiguration.fullNodesInstanceTypeId;

	const items = name === 'validator' ? [...(validators || [])] : [...(fullNodes || [])];

	if (initialNumber > newValue) {
		if (instanceClassId !== newConfiguration.instanceClassId || initialInstanceTypeId !== newInstanceTypeId) {
			const newItems = items.map(item => {
				return new SimpleModel({
					...item,
					instanceClassId: newConfiguration.instanceClassId,
					instanceTypeId: newInstanceTypeId
				});
			});

			return newItems.slice(0, newValue);
		}

		return items.slice(0, newValue);
	}

	for (let i = initialNumber + 1; i <= newValue; i++) {
		items.push(new SimpleModel({
			id: i,
			name: `${name}-${i.toString().padStart(3, '0')}`,
			instanceClassId: newConfiguration.instanceClassId,
			instanceTypeId: newInstanceTypeId
		}));
	}

	if (instanceClassId !== newConfiguration.instanceClassId || initialInstanceTypeId !== newInstanceTypeId) {
		const newItems = items.map(item => {
			return new SimpleModel({
				...item,
				instanceClassId: newConfiguration.instanceClassId,
				instanceTypeId: newInstanceTypeId
			});
		});

		return newItems;
	}

	return items;
}