import { Route } from "./routeModel";

type RouteObject = {
    [key: string]: Route
}

export const getRouteObject = (rootRoutes: Route[] = []) => {
    const routesObject: RouteObject = {};

	for (const route of rootRoutes) {
		routesObject[route.id] = route;
    }

    return routesObject;
}
