import { Form, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ServiceModel } from './serviceFormModel';

type Props = {
	service?: ServiceModel
	onSave: (newService: ServiceModel) => void
	cancel(): void
}

export const ServiceForm = ({ service, onSave, cancel }: Props) => {
	const [values, setValues] = useState(service || new ServiceModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<InputNumberField
								id={propertyOf<ServiceModel>('maxSlots')}
								label='Max Slots'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<ServiceModel>('maxEnqueued')}
								label='Max Enqueued'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<ServiceModel>('epochSize')}
								label='Epoch Size'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<ServiceModel>('blockGasLimit')}
								label='Block Gas Limit'
								isRequired
							/>
						</SmartItem>
						<SmartItem>
							<InputNumberField
								id={propertyOf<ServiceModel>('blockTime')}
								label='Block Time (seconds)'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<ServiceModel>('gasPrice')}
								label='Gas Price'
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
