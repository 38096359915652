// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_spinner__du8pg {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner_spinner__du8pg > :not(:first-child) {
  margin-top: 10px;
}

.spinner_text__0eQXx {
  text-align: center;
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/spinner.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACG,aAAA;EACA,sBAAA;EACA,mBAAA;EACH,uBAAA;AACD;AACC;EACC,gBAAA;AACF;;AAGA;EACC,kBAAA;EACA,gBAAA;AAAD","sourcesContent":[".spinner {\n\theight: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\tjustify-content: center;\n\n\t> :not(:first-child) {\n\t\tmargin-top: 10px;\n\t}\n}\n\n.text {\n\ttext-align: center;\n\tline-height: 1.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `spinner_spinner__du8pg`,
	"text": `spinner_text__0eQXx`
};
export default ___CSS_LOADER_EXPORT___;
