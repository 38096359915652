import { Tabs } from 'components/Tabs/Tabs'
import { bootstrapServiceTabs } from './bootstrapServiceTabs'
import { ColumnContainer } from 'components/Layout'

export const BootstrapAndService = () => {
	return (
		<ColumnContainer whiteBoxContainer>
			<Tabs tabs={bootstrapServiceTabs} />
		</ColumnContainer>
	)
}
