import { NetworkModel } from "feature/Network/NetworkModel";
import { NetworkConfigurationControllerClient, SaveNetworkConfigurationRequest } from "services/bladeApiService";

export const getAllNetworkConfigurationAction = () => {
	const client = new NetworkConfigurationControllerClient();
	return client.getAllNetworkConfiguration();
}

export const getOneNetworkConfigurationAction = (networkId: number) => {
	const client = new NetworkConfigurationControllerClient();
	return client.getOneNetworkConfiguration(networkId);
}

export const saveNetworkConfigurationAction = (networkConfigurationRequest: SaveNetworkConfigurationRequest) => {
	const client = new NetworkConfigurationControllerClient();
	return client.saveNetworkConfiguration(networkConfigurationRequest);
}

// redux
type ActionType = {
	type: string
};

export type SetNetworksActionType = ActionType & {
	networks: NetworkModel[];
};

export type SetNetworkActionType = ActionType & {
	network: NetworkModel;
};

export type CreateNetworkActionType = SetNetworkActionType;

// export type SetConfigurationActionType = ActionType & {
// 	configuration: ConfigurationModel
// }

export const NETWORKS_SET = 'NETWORKS_SET';
export const NETWORK_SET = 'NETWORK_SET';
export const NETWORK_CREATE = 'NETWORK_CREATE';

export const setNetworksAction = (networks: NetworkModel[]) => {
	return {
		type: NETWORKS_SET,
		networks
	} as SetNetworksActionType
}

export const setNetworkAction = (network: NetworkModel) => {
	return {
		type: NETWORK_SET,
		network
	} as SetNetworkActionType
}

export const createNetworkAction = (network: NetworkModel) => {
	return {
		type: NETWORK_CREATE,
		network
	} as CreateNetworkActionType
}
