// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clipSpinner_spinner_clip__is3Sn {
  background: transparent;
  border-radius: 50%;
  border: 2px solid;
  animation-fill-mode: both;
  animation: clipSpinner_spinner_clip__is3Sn 0.75s 0s infinite linear;
}

@keyframes clipSpinner_spinner_clip__is3Sn {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/ClipSpinner/clipSpinner.module.scss","webpack://./src/components/Spinner/mixins.scss"],"names":[],"mappings":"AAEA;ECDC,uBAAA;EACA,kBAAA;EACA,iBAAA;EDCA,yBAAA;EACA,mEAAA;AACD;;AAEA;EACC;IACC,gCAAA;EACA;EACD;IACC,oCAAA;EACA;EACD;IACC,kCAAA;EACA;AACF","sourcesContent":["@import '../mixins.scss';\n\n.spinner_clip {\n\t@include border_spinner();\n\tanimation-fill-mode: both;\n\tanimation: spinner_clip 0.75s 0s infinite linear;\n}\n\n@keyframes spinner_clip {\n\t0% {\n\t\ttransform: rotate(0deg) scale(1);\n\t}\n\t50% {\n\t\ttransform: rotate(180deg) scale(0.8);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg) scale(1);\n\t}\n}\n","@mixin border_spinner() {\n\tbackground: transparent;\n\tborder-radius: 50%;\n\tborder: 2px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner_clip": `clipSpinner_spinner_clip__is3Sn`
};
export default ___CSS_LOADER_EXPORT___;
