import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { EthernalBlockExplorerModel } from '../ethernalBlockExplorerFormModel';
import { StepSubRoutesEnum } from 'containers/BlockExplorers/routes';
import { ConfigurationStepTwoForm } from './ConfigurationStepTwoForm';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { NetworkModel } from 'feature/Network/NetworkModel';

type ParamType = {
	networkId: string
}

export const ConfigurationStepTwo = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (values: EthernalBlockExplorerModel) => {
			if (!network) {
				return;
			}

			const ethernalModel = new EthernalBlockExplorerModel({
				...network.ethernal,
				numberOfWorkers: values.numberOfWorkers,
				checkpoint: values.checkpoint,
				checkpointDistance: values.checkpointDistance,
				gatewayURL: values.gatewayURL,
				step: values.step,
				checkpointWindow: values.checkpointWindow,
				callTimeout: values.callTimeout,
				includeLOGs: values.includeLOGs,
				includeNFTs: values.includeNFTs,
			});

			const networkModel = new NetworkModel({
				...network,
				ethernal: ethernalModel,
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${history.location.pathname.split('/').slice(0, -1).join('/')}/${StepSubRoutesEnum.STEP_THREE}`);
			}
		},
		[network, history, dispatch]
	);

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Ethernal Block Explorer' subtitle='Configuration' />
			<ConfigurationStepTwoForm
				configEthernal={network?.ethernal}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
			/>
		</ColumnContainer>
	)
}
