import { useLocation } from 'react-router-dom';
import { Item } from './Item';
import { Route } from 'feature/Routes/routeModel';
import styles from './submenu.module.scss';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';

type Props = {
	items: Route[]
	networkId: string
	networkName: string | undefined
	isNetworkDeploy: boolean
}

export const Submenu = ({ items, networkId, networkName , isNetworkDeploy}: Props) => {
	const { pathname } = useLocation();

	const submenuItems = items.map((item) => {
		return (
			<Item
				key={item.id}
				url={item.url}
				text={item.text}
				networkId={networkId}
				networkName={networkName}
				isActive={pathname.includes(item.url)}
				isAccountItem={item.id === RootRouteEnum.PROFILE}
				isNetworkDeploy={isNetworkDeploy}
			/>
		)
	})

	return (
		<div className={styles.container}>
			{submenuItems}
		</div>
	)
}
