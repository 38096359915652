import { AutoCompleteField, Form, InputField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { DetailsModel } from './networkFormModel';

export const regions = [
	{id: 1, name: 'us-west-1'},
	{id: 2, name: 'us-west-2'},
];

type Props = {
	network: DetailsModel
	onSave: (newNetwork: DetailsModel) => void
	cancel(): void
}

export const NetworkForm = ({ network, onSave, cancel }: Props) => {
	const [values, setValues] = useState(network);

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<ColumnContainer>
			<Form
				values={values}
				initialValues={network}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={cancel}
				render={() => (
					<>
						<InputField
							id={propertyOf<DetailsModel>('domain')}
							label='Domain'
							isRequired
						/>
						<InputField
							id={propertyOf<DetailsModel>('name')}
							label='Deployment Name'
							isRequired
						/>
						<AutoCompleteField
							id={propertyOf<DetailsModel>('cloudServiceId')}
							label='Cloud Service'
							items={[{id: 1, name: 'AWS'}]}
							getItemId={(item: any) => item.id}
							getItemText={(item: any) => item.name}
							disabled
						/>
						<AutoCompleteField
							id={propertyOf<DetailsModel>('regionId')}
							label='Region'
							items={regions}
							getItemId={(item: any) => item.id}
							getItemText={(item: any) => item.name}
							isRequired
						/>
					</>
				)}
			/>
		</ColumnContainer>
	)
}
