import appSettings from 'settings/appSettings';
import { getAccessToken } from 'utils/fetchUtils';

export abstract class BaseClient {
	protected async transformOptions(originalOptions: RequestInit): Promise<RequestInit> {
		const accessToken = getAccessToken();

		return Promise.resolve({
			...originalOptions,
			headers: {
				...originalOptions.headers,
				"Authorization": `Bearer ${accessToken}`
			},
		});
	}

	protected getBaseUrl(defaultUrl: string, baseUrl?: string): string {
		return appSettings.getApiUrl()
	}
}
