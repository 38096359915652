import { AutoCompleteField, Form, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { ConfigurationModel } from './myNetworkFormModel';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ConfigurationDetails } from './ConfigurationDetails';
import { InstanceItemType, instanceClass, instanceType } from 'data/awsData';
import { useInstanceTypeDescriptionCallback } from 'feature/Network/networkHook';

type Props = {
	configNetwork?: ConfigurationModel
	onSave: (newConfig: ConfigurationModel) => void
	cancel(): void
}

export const ConfigurationMyNetworkForm = ({ configNetwork, onSave, cancel }: Props) => {
	const [values, setValues] = useState(configNetwork || new ConfigurationModel());

	const getDescription = useInstanceTypeDescriptionCallback(instanceType);

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='xxsmall'>
							<AutoCompleteField
								id={propertyOf<ConfigurationModel>('instanceClassId')}
								label='Instance Class'
								items={instanceClass}
								getItemId={(item: InstanceItemType) => item.id}
								getItemText={(item: InstanceItemType) => item.name}
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
					<SmartContainer>
						<SmartItem size='xxsmall'>
							<SmartFormGroup label='Validators'>
								<>
									<InputNumberField
										id={propertyOf<ConfigurationModel>('validatorsNumber')}
										label='Number'
										isRequired
									/>
									<AutoCompleteField
										id={propertyOf<ConfigurationModel>('validatorsInstanceTypeId')}
										label='Instance Type'
										items={instanceType}
										getItemId={(item: InstanceItemType) => item.id}
										getItemText={(item: InstanceItemType) => item.name}
										isRequired
									/>
									{values.validatorsInstanceTypeId &&
										<small style={{color: '#6F6C6C'}}>* {getDescription(values.validatorsInstanceTypeId)}</small>
									}
									<InputNumberField
										id={propertyOf<ConfigurationModel>('validatorsDeviceCapacity')}
										label='Root Device Capacity'
										suffix=' GB'
										isRequired
									/>
								</>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem size='xxsmall'>
							<SmartFormGroup label='Full Nodes'>
								<>
									<InputNumberField
										id={propertyOf<ConfigurationModel>('fullNodesNumber')}
										label='Number'
										isRequired
									/>
									<AutoCompleteField
										id={propertyOf<ConfigurationModel>('fullNodesInstanceTypeId')}
										label='Instance Type'
										items={instanceType}
										getItemId={(item: InstanceItemType) => item.id}
										getItemText={(item: InstanceItemType) => item.name}
										isRequired
									/>
									{values.fullNodesInstanceTypeId &&
										<small style={{color: '#6F6C6C'}}>* {getDescription(values.fullNodesInstanceTypeId)}</small>
									}
									<InputNumberField
										id={propertyOf<ConfigurationModel>('fullNodesDeviceCapacity')}
										label='Root Device Capacity'
										suffix=' GB'
										isRequired
									/>
								</>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem>
							<ConfigurationDetails showInfoNote={!!values.instanceClassId} />
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
