import { AutoCompleteField, Form, InputField } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AdditionalAccountsModel } from './additionalAccountsFormModal';
import { ListOfAccounts } from './ListOfAccounts/ListOfAccounts';
import { Button } from 'components/Button';

type Props = {
	additionalAccountsItems: AdditionalAccountsModel[] | undefined
	onSave: (newAccount: AdditionalAccountsModel) => void
	cancel(): void
}

export const AdditionalAccountsForm = ({ additionalAccountsItems, onSave, cancel }: Props) => {
	const [values, setValues] = useState(new AdditionalAccountsModel());
	const [items, setItems] = useState(additionalAccountsItems || []);

	const addAccountCallback = useCallback(
		() => {
			if (!values.address || !values.balance) {
				return;
			}

			const id = Math.floor(Math.random() * new Date().getTime());

			const newAccount = new AdditionalAccountsModel({
				id,
				address: values.address,
				balance: values.balance,
				type: values.type
			});

			setItems((state) => [...state, newAccount]);
			setValues(new AdditionalAccountsModel());
		},
		[values]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const newValue = new AdditionalAccountsModel({
				...values,
				accountItems: items
			});

			await onSave(newValue);
		},
		[values, onSave, items]
	)

	const handleRemoveAccountCallback = useCallback(
		(id: number) => {
			const filteredItems = items.filter(item => item.id !== id);
			setItems(filteredItems);
		},
		[items]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='small'>
							<InputField
								id={propertyOf<AdditionalAccountsModel>('address')}
								label='Address'
							/>
							<SmartInline>
								<InputField
									id={propertyOf<AdditionalAccountsModel>('balance')}
									label='Balance'
								/>
								<AutoCompleteField
									id={propertyOf<AdditionalAccountsModel>('type')}
									label='Type'
									items={[{id: 1, name: 'WEI'}, {id: 2, name: 'ISO'}]}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
								/>
							</SmartInline>
							<VerticalSeparator margin='medium'/>
							<Button
								onClick={addAccountCallback}
								text='Add'
							/>
						</SmartItem>
						<SmartItem size='small'>
							<ListOfAccounts
								title='List of Accounts'
								items={items}
								removeAccount={handleRemoveAccountCallback}
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

