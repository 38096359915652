import { AdditionalAccountsModel } from '../additionalAccountsFormModal'
import { getCssVariableValue } from 'utils/cssVariablesUtils'
import { Item } from './Item'
import styles from './listOfAccounts.module.scss'

type Props = {
	items: AdditionalAccountsModel[] | undefined
	title: string
	removeAccount(id: number): void
}

export const ListOfAccounts = ({ items, title, removeAccount }: Props) => {
	const iconColor = getCssVariableValue('--secondary-color');
	const removeIconColor = getCssVariableValue('--error-color');

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				{title}
			</div>
			{items && items.length > 0 ?
				items?.map((item) =>
					<Item
						key={item.id}
						item={item}
						removeAccount={removeAccount}
						iconColor={iconColor}
						removeIconColor={removeIconColor}
					/>
				)
				:
				<span className={styles.info}>You don't have any Account added.</span>
			}
		</div>

	)
}
