// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationComponent_container__MP0jn {
  margin-left: 10px;
}

.notificationComponent_message__k6qoj {
  vertical-align: middle;
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/notificationComponent.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACC,sBAAA;EACG,sBAAA;AACJ","sourcesContent":[".container {\n    margin-left: 10px;\n}\n\n.message {\n\tvertical-align: middle;\n    word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `notificationComponent_container__MP0jn`,
	"message": `notificationComponent_message__k6qoj`
};
export default ___CSS_LOADER_EXPORT___;
