import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { PolygonEdgeSubRoutesEnum } from './routes'
import { PolygonEdge } from './PolygonEdge'
import { AdvancedConfiguration } from './AdvancedConfiguration/AdvancedConfiguration'

export const PolygonEdgeRouter = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${PolygonEdgeSubRoutesEnum.ADVANCED_CONFIGURATION}`}
				render={
					() => <AdvancedConfiguration />
				}
			/>

			<Route
				render={
					() => <PolygonEdge />
				}
			/>
		</Switch>
	)
}
