import { AuthControllerClient, UserLoginRequest } from "services/bladeApiService";

export const loginAction = (loginRequest: UserLoginRequest) => {
	const client = new AuthControllerClient();
	return client.login(loginRequest);
}

export const getAccessTokenAction = (refreshToken: string) => {
	const client = new AuthControllerClient();
	return client.getAccessToken(refreshToken);
}

export const getUserDataAction = () => {
	const client = new AuthControllerClient();
	return client.getUserData();
}
