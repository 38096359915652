import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getRouteObject } from 'feature/Routes/routesHelper';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route as RouteType } from 'feature/Routes/routeModel';
import { myNetworkRoute } from 'containers/MyNetwork/routes';
import { MyNetworkRouter } from 'containers/MyNetwork/MyNetworkRouter';
import { polygonEdgeRoute } from 'containers/PolygonEdge/routes';
import { accountRoute } from 'containers/Account/routes';
import { Profile } from 'containers/Account/Profile';
import { PolygonEdgeRouter } from 'containers/PolygonEdge/PolygonEdgeRouter';
import { bootstrapAndServiceRoute } from 'containers/BootstrapAndService/routes';
import { BootstrapAndService } from 'containers/BootstrapAndService/BootstrapAndService';
import { validatorsRoute } from 'containers/Validators/routes';
import { Validators } from 'containers/Validators/Validators';
import { fullNodesRoute } from 'containers/FullNodes/routes';
import { FullNodes } from 'containers/FullNodes/FullNodes';
import { loadBalancersRoute } from 'containers/LoadBalancers/routes';
import { LoadBalancersRouter } from 'containers/LoadBalancers/LoadBalancersRouter';
import { blockExplorersRoute } from 'containers/BlockExplorers/routes';
import { BlockExplorersRouter } from 'containers/BlockExplorers/BlockExplorersRouter';
import { additionalAccountsRoute } from 'containers/AdditionalAccounts/routes';
import { AdditionalAccounts } from 'containers/AdditionalAccounts/AdditionalAccounts';
import { customContractsRoute } from 'containers/CustomContracts/routes';
import { CustomContracts } from 'containers/CustomContracts/CustomContracts';
import { NetworkRouter } from 'containers/Network/NetworkRouter';

type Props = {
	rootRoutes: RouteType[]
	hasNetwork: boolean
}

export const ContentRouter = ({ rootRoutes, hasNetwork }: Props) => {
	const { path } = useRouteMatch();

	const routesMap = useMemo(
		() => getRouteObject(rootRoutes),
		[rootRoutes]
	)

	return (
		<Switch>
			{routesMap[RootRouteEnum.MYNETWORK] &&
				<Route
					path={`${path}/${myNetworkRoute.url}`}
					render={
						() => <MyNetworkRouter />
					}
				/>
			}

			{routesMap[RootRouteEnum.POLYGON_EDGE] &&
				<Route
					path={`${path}/${polygonEdgeRoute.url}`}
					render={
						() => <PolygonEdgeRouter />
					}
				/>
			}

			{routesMap[RootRouteEnum.BOOTSTRAP_SERVICE] &&
				<Route
					path={`${path}/${bootstrapAndServiceRoute.url}`}
					render={
						() => <BootstrapAndService />
					}
				/>
			}

			{routesMap[RootRouteEnum.VALIDATORS] &&
				<Route
					path={`${path}/${validatorsRoute.url}`}
					render={
						() => <Validators />
					}
				/>
			}

			{routesMap[RootRouteEnum.FULL_NODES] &&
				<Route
					path={`${path}/${fullNodesRoute.url}`}
					render={
						() => <FullNodes />
					}
				/>
			}

			{routesMap[RootRouteEnum.LOAD_BALANCERS] &&
				<Route
					path={`${path}/${loadBalancersRoute.url}`}
					render={
						() => <LoadBalancersRouter />
					}
				/>
			}

			{routesMap[RootRouteEnum.BLOCK_EXPLORERS] &&
				<Route
					path={`${path}/${blockExplorersRoute.url}`}
					render={
						() => <BlockExplorersRouter />
					}
				/>
			}

			{routesMap[RootRouteEnum.ADDITIONAL_ACCOUNTS] &&
				<Route
					path={`${path}/${additionalAccountsRoute.url}`}
					render={
						() => <AdditionalAccounts />
					}
				/>
			}

			{routesMap[RootRouteEnum.CUSTOM_CONTRACTS] &&
				<Route
					path={`${path}/${customContractsRoute.url}`}
					render={
						() => <CustomContracts />
					}
				/>
			}

			{routesMap[RootRouteEnum.PROFILE] &&
				<Route
					path={`${path}/${accountRoute.url}`}
					render={
						() => <Profile />
					}
				/>
			}

			<Route
				render={() => <NetworkRouter hasNetwork={hasNetwork} />}
			/>
		</Switch>
	)
}
