// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homePage_container__qPwU7 {
  display: flex;
  flex-direction: row;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/homePage.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,YAAA;AACD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `homePage_container__qPwU7`
};
export default ___CSS_LOADER_EXPORT___;
