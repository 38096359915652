// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
  border-radius: 5px !important;
}

.Toastify__close-button {
  visibility: hidden;
}

.Toastify__toast-container--top-center {
  width: 900px;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/toastrOverride.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".Toastify__toast {\n\tborder-radius: 5px !important;\n}\n\n.Toastify__close-button {\n\tvisibility: hidden;\n}\n\n.Toastify__toast-container--top-center {\n    width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
