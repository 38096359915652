// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewNetwork_container__YP\\+3H {
  display: flex;
  flex-wrap: wrap;
}
.viewNetwork_container__YP\\+3H > div {
  margin-right: 24px;
  margin-bottom: 24px;
}

.viewNetwork_content__Ud-wx {
  background-color: var(--field-background);
  padding: 32px;
  border-radius: 5px;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);
  min-width: 380px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Network/ViewNetwork/viewNetwork.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;AACD;AACC;EACC,kBAAA;EACA,mBAAA;AACF;;AAGA;EACC,yCAAA;EACA,aAAA;EACA,kBAAA;EACA,8CAAA;EACA,gBAAA;AAAD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\n\t& > div {\n\t\tmargin-right: 24px;\n\t\tmargin-bottom: 24px;\n\t}\n}\n\n.content {\n\tbackground-color: var(--field-background);\n\tpadding: 32px;\n\tborder-radius: 5px;\n\tbox-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);\n\tmin-width: 380px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `viewNetwork_container__YP+3H`,
	"content": `viewNetwork_content__Ud-wx`
};
export default ___CSS_LOADER_EXPORT___;
