import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { EthernalBlockExplorer } from './EthernalBlockExplorer'
import { StepSubRoutesEnum } from '../routes'
import { ConfigurationStepTwo } from './StepTwo/ConfigurationStepTwo'
import { ConfigurationStepThree } from './StepThree/ConfigurationStepThree'

export const EthernalBlockExplorerRouter = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route
				path={`${path}/${StepSubRoutesEnum.STEP_THREE}`}
				render={
					() => <ConfigurationStepThree />
				}
			/>
			<Route
				path={`${path}/${StepSubRoutesEnum.STEP_TWO}`}
				render={
					() => <ConfigurationStepTwo />
				}
			/>

			<Route
				render={
					() => <EthernalBlockExplorer />
				}
			/>
		</Switch>
	)
}
