import { MyNetworkIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

export const myNetworkRoute: Route = {
	id: RootRouteEnum.MYNETWORK,
	url: RootRouteEnum.MYNETWORK,
	text: 'My Network',
	Icon: MyNetworkIcon
}

export enum MyNetworkSubRoutesEnum {
	CONFIGURATION = 'configuration',
}
