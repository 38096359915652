import { networkRoute } from 'containers/Network/routes'
import { Menu as MenuContainer } from 'containers/Menu/Menu'
import { Submenu } from 'containers/Menu/Submenu/Submenu'
import { myNetworkRoute } from 'containers/MyNetwork/routes'
import { ContentContainer } from 'feature/Content/ContentContainer'
import { polygonEdgeRoute } from 'containers/PolygonEdge/routes'
import { bootstrapAndServiceRoute } from 'containers/BootstrapAndService/routes'
import { validatorsRoute } from 'containers/Validators/routes'
import { fullNodesRoute } from 'containers/FullNodes/routes'
import { loadBalancersRoute } from 'containers/LoadBalancers/routes'
import { blockExplorersRoute } from 'containers/BlockExplorers/routes'
import { additionalAccountsRoute } from 'containers/AdditionalAccounts/routes'
import { customContractsRoute } from 'containers/CustomContracts/routes'
import styles from './homePage.module.scss'
import { useCallback, useEffect, useMemo } from 'react'
import { getAllNetworkConfigurationAction, setNetworksAction } from 'feature/Network/actions'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { useDispatch } from 'react-redux'
import { NetworkModel } from 'feature/Network/NetworkModel'
import { useParams } from 'react-router-dom'
import { useNetworkMemo } from 'feature/Network/networkHook'
import { accountRoute } from 'containers/Account/routes'

type ParamType = {
	networkId: string
}

export const HomePage = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(() => getAllNetworkConfigurationAction());
			if (response.success) {
				const networks: NetworkModel[] = [];
				for (const item of response.items || []) {
					networks.push(JSON.parse(item.networkConfiguration));
				}
				dispatch(setNetworksAction(networks));
			}
		},
		[dispatch]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	const menuRoutes = useMemo(
		() => {
			const initialRoutes = [networkRoute];

			if (network) {
				const { id, isInitialized, isPolygonEdgeInitialized, isBootstrapAndServiceInitialized, isBlockExplorersInitialized } = network;

				if (id) {
					initialRoutes.push(myNetworkRoute);
				}

				if (isInitialized) {
					initialRoutes.push(polygonEdgeRoute);
				}

				if (isPolygonEdgeInitialized) {
					initialRoutes.push(bootstrapAndServiceRoute);
				}

				if (isBootstrapAndServiceInitialized) {
					initialRoutes.push(validatorsRoute, fullNodesRoute, loadBalancersRoute, blockExplorersRoute, additionalAccountsRoute, customContractsRoute);
				}

				if (isBlockExplorersInitialized) {
					initialRoutes.push(accountRoute);
				}
			}

			return initialRoutes;
		},
		[network]
	)

	return (
		<div className={styles.container}>
			<MenuContainer
				items={menuRoutes}
				networkId={networkId}
			/>
			{(network && network.id) &&
				<Submenu
					items={menuRoutes.slice(1)}
					networkId={networkId}
					networkName={network?.details?.name}
					isNetworkDeploy={network.isDeploy}
				/>
			}
			<ContentContainer
				rootRoutes={menuRoutes}
				hasNetwork={networkId ? true : false}
			/>
		</div>
	)
}
