import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { EthernalBlockExplorerModel } from '../ethernalBlockExplorerFormModel';
import { ConfigurationStepThreeForm } from './ConfigurationStepThreeForm';
import { networkRoute } from 'containers/Network/routes';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { myNetworkRoute } from 'containers/MyNetwork/routes';

type ParamType = {
	networkId: string
}

export const ConfigurationStepThree = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (values: EthernalBlockExplorerModel) => {
			if (!network) {
				return;
			}

			const ethernalModel = new EthernalBlockExplorerModel({
				...network.ethernal,
				backgroundColor: values.backgroundColor,
				headerTitle: values.headerTitle,
				username: values.username,
				transactionsMaxCount: values.transactionsMaxCount,
				latestTransfersMaxCount: values.latestTransfersMaxCount,
				mainColor: values.mainColor,
				mainTitle: values.mainTitle,
				stepThreePassword: values.stepThreePassword,
				transactionsByAddressMaxCount: values.transactionsByAddressMaxCount,

			});

			const networkModel = new NetworkModel({
				...network,
				ethernal: ethernalModel,
				isBlockExplorersInitialized: true
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[history, dispatch, network]
	);

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Ethernal Block Explorer' subtitle='Configuration' />
			<ConfigurationStepThreeForm
				configEthernal={network?.ethernal}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
			/>
		</ColumnContainer>
	)
}
