import { AutoCompleteField, Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { PolygonEdgeModel } from './polygonEdgeFormModel';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Button } from 'components/Button';

const versions = [
	{id: 1, name: 'latest'},
	{id: 2, name: 'old'},
]

const protocols = [
	{id: 1, name: 'polybft'},
	{id: 2, name: 'test001'}
];

const eip = [
	{id: true, name: 'yes'},
	{id: false, name: 'no'},
];

type Props = {
	polygonEdge?: PolygonEdgeModel
	onSave: (newPolygonEdge: PolygonEdgeModel) => void
	cancel(): void
	onAdvancedConfigurationClick(): void
}

export const PolygonEdgeForm = ({ polygonEdge, onSave, cancel, onAdvancedConfigurationClick }: Props) => {
	const [values, setValues] = useState(polygonEdge || new PolygonEdgeModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='xsmall'>
							<AutoCompleteField
								id={propertyOf<PolygonEdgeModel>('version')}
								label='Polygon Edge Version'
								items={versions}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
							<InputNumberField
								id={propertyOf<PolygonEdgeModel>('chainId')}
								label='Chain Id'
								isRequired
							/>
							<InputField
								id={propertyOf<PolygonEdgeModel>('nativeToken')}
								label='Native Token'
								isRequired
							/>
						</SmartItem>
						<SmartItem size='xsmall'>
							<AutoCompleteField
								id={propertyOf<PolygonEdgeModel>('consensusProtocol')}
								label='Consensus Protocol'
								items={protocols}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<PolygonEdgeModel>('enableEip')}
								label='Enable EIP-1559'
								items={eip}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
						</SmartItem>
						<SmartItem>
							<div style={{width: '380px'}}></div>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
			renderAdditionalButtons={() => (
				<div style={{marginLeft: '-24px'}}>
					<Button
						text='Advanced Configuration'
						color='neutral'
						onClick={onAdvancedConfigurationClick}
					/>
				</div>
			)}
		/>
	)
}
