import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { AdvancedConfigurationBlockscoutForm } from './AdvancedConfigurationBlockscoutForm';
import { StepSubRoutesEnum } from 'containers/BlockExplorers/routes';
import { AdvancedConfigurationBlockscoutModel, BlockscoutModel } from '../blockscoutFormModel';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

type ParamType = {
	networkId: string
}

export const AdvancedConfigurationBlockscout = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newConfiguration: AdvancedConfigurationBlockscoutModel) => {
			if (!network) {
				return;
			}

			const blockscoutModel = new BlockscoutModel({
				...network.blockscout,
				advancedConfiguration: newConfiguration
			});

			const networkModel = new NetworkModel({
				...network,
				blockscout: blockscoutModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${history.location.pathname.split('/').slice(0, -1).join('/')}/${StepSubRoutesEnum.STEP_TWO}`);
			}
		},
		[network, history, dispatch]
	);

	const advancedConfigurationMemo = useMemo(
		() => {
			if (network?.blockscout?.advancedConfiguration) {
				return network.blockscout.advancedConfiguration;
			}

			const model = new AdvancedConfigurationBlockscoutModel();
			model.databaseURL = 'postgresql://blockscout';
			model.subNetwork = 'Polygon Edge';
			model.coin = 'Edge';
			model.chainId = 100;
			model.heartCommand = 'systemctl restart explorer';
			model.secretKeyBase = 'systemctl restart explorer';
			model.coinName = 'Edge coin';
			model.isDisableBlockReward = true;
			model.isDisablePendingTransactions = true;
			return model;
		},
		[network?.blockscout]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Blockscout' subtitle='Advanced Configuration' />
			<AdvancedConfigurationBlockscoutForm
				advancedConfiguration={advancedConfigurationMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
			/>
		</ColumnContainer>
	)
}

