import { PolygonEdgeIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const polygonEdgeRoute: Route = {
	id: RootRouteEnum.POLYGON_EDGE,
	url: RootRouteEnum.POLYGON_EDGE,
	text: 'Polygon Edge',
	Icon: PolygonEdgeIcon,
}

// subroute enum
export enum PolygonEdgeSubRoutesEnum {
	ADVANCED_CONFIGURATION = 'advanced_configuration',
}
