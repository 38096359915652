// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listOfAccounts_container__pOHSx {
  display: flex;
  flex-direction: column;
}

.listOfAccounts_header__SvB3A {
  line-height: 32px;
  border-bottom: 1px solid var(--secondary-color);
  margin-bottom: 16px;
}

.listOfAccounts_content__aCsym {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.listOfAccounts_content__aCsym .listOfAccounts_item__qvVxN {
  flex: 1 1;
}
.listOfAccounts_content__aCsym .listOfAccounts_item__qvVxN .listOfAccounts_row__O4x7T {
  display: flex;
  flex-wrap: wrap;
}
.listOfAccounts_content__aCsym .listOfAccounts_item__qvVxN .listOfAccounts_row__O4x7T .listOfAccounts_icon__v1UX6 {
  margin-right: 8px;
}
.listOfAccounts_content__aCsym .listOfAccounts_remove__idMxm {
  cursor: pointer;
}
.listOfAccounts_content__aCsym .listOfAccounts_remove__idMxm svg:hover {
  fill: var(--active-color-1);
}

.listOfAccounts_info__NrI-M {
  color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/containers/AdditionalAccounts/ListOfAccounts/listOfAccounts.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;AACD;;AAEA;EACC,iBAAA;EACA,+CAAA;EACA,mBAAA;AACD;;AAEA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACD;AACC;EACC,SAAA;AACF;AACE;EACC,aAAA;EACA,eAAA;AACH;AACG;EACC,iBAAA;AACJ;AAIC;EACC,eAAA;AAFF;AAIE;EACC,2BAAA;AAFH;;AAOA;EACC,6BAAA;AAJD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.header {\n\tline-height: 32px;\n\tborder-bottom: 1px solid var(--secondary-color);\n\tmargin-bottom: 16px;\n}\n\n.content {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tmargin-bottom: 8px;\n\n\t.item {\n\t\tflex: 1;\n\n\t\t.row {\n\t\t\tdisplay: flex;\n\t\t\tflex-wrap: wrap;\n\n\t\t\t.icon {\n\t\t\t\tmargin-right: 8px;\n\t\t\t}\n\t\t}\n\t};\n\n\t.remove {\n\t\tcursor: pointer;\n\n\t\t& svg:hover {\n\t\t\tfill: var(--active-color-1);\n\t\t}\n\t}\n}\n\n.info {\n\tcolor: var(--secondary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `listOfAccounts_container__pOHSx`,
	"header": `listOfAccounts_header__SvB3A`,
	"content": `listOfAccounts_content__aCsym`,
	"item": `listOfAccounts_item__qvVxN`,
	"row": `listOfAccounts_row__O4x7T`,
	"icon": `listOfAccounts_icon__v1UX6`,
	"remove": `listOfAccounts_remove__idMxm`,
	"info": `listOfAccounts_info__NrI-M`
};
export default ___CSS_LOADER_EXPORT___;
