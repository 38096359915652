import { Dialog } from 'components/Dialog';
import { EditForm } from './EditForm';
import { SimpleModel } from '../ConfigurationMyNetwork/myNetworkFormModel';

type Props = {
	item: SimpleModel
	isOpen: boolean
	close(): void
	onEditSave?(values: SimpleModel): Promise<void>
	isValidatorsOrFullNodes?: 'validator' | 'fullnode'
}

export const EditModal = ({ item, isOpen, close, onEditSave, isValidatorsOrFullNodes }: Props) => {

	return (
		<Dialog
			title={item.name ? `Edit - ${item.name}` : 'Edit'}
			onClose={close}
			open={isOpen}
		>
			<EditForm
				configuration={item}
				cancel={close}
				isValidatorsOrFullNodes={isValidatorsOrFullNodes}
				onSave={onEditSave}
			/>
		</Dialog>
	)
}
