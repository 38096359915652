import { AutoCompleteField, Form, InputField, InputNumberField } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AdvancedConfigurationModel } from '../polygonEdgeFormModel';


const hasAdditionalStorage = [
	{id: true, name: 'yes'},
	{id: false, name: 'no'},
];

type Props = {
	configuration?: AdvancedConfigurationModel
	onSave: (newConfiguration: AdvancedConfigurationModel) => void
	cancel(): void
}

export const AdvancedConfigurationForm = ({ configuration, onSave, cancel }: Props) => {
	const [values, setValues] = useState(configuration || new AdvancedConfigurationModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Save'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<InputField
								id={propertyOf<AdvancedConfigurationModel>('directory')}
								label='Home Directory'
								isRequired
							/>
							<VerticalSeparator margin='small' />
							<SmartInline>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationModel>('gRpcPort')}
									label='GRPC Port'
									isRequired
								/>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationModel>('p2pPort')}
									label='P2P Port'
									isRequired
								/>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationModel>('jsonRpcPort')}
									label='JSON-RPC Port'
									isRequired
								/>
							</SmartInline>
							<VerticalSeparator margin='small' />
							<AutoCompleteField
								id={propertyOf<AdvancedConfigurationModel>('additionalStorage')}
								label='Additional Storage'
								items={hasAdditionalStorage}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
							<SmartInline>
								<InputField
									id={propertyOf<AdvancedConfigurationModel>('deviceName')}
									label='Storage Device Name'
									isRequired
								/>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationModel>('deviceCapacity')}
									label='Storage Device Capacity'
									suffix=' GB'
									isRequired
								/>
							</SmartInline>
						</SmartItem>
						<SmartItem>
							<InputField
								id={propertyOf<AdvancedConfigurationModel>('location')}
								label='Storage Device Location'
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationModel>('mountPoint')}
								label='Storage Mount Point'
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationModel>('partitionName')}
								label='Storage Partition Name'
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
