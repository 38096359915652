export class EthernalBlockExplorerModel {
	name?: string | undefined;
	instanceClassId?: number | undefined;
	instanceTypeId?: number | undefined;
	databaseName?: string | undefined;
	host?: string | undefined;
	port?: number | undefined;
	password?: string | undefined;
	user?: string | undefined;
	numberOfWorkers?: number | undefined;
	checkpoint?: number | undefined;
	checkpointDistance?: number | undefined;
	gatewayURL?: string | undefined;
	step?: number | undefined;
	checkpointWindow?: number | undefined;
	callTimeout?: number | undefined;
	includeLOGs?: boolean | undefined;
	includeNFTs?: boolean | undefined;
	mainColor?: string | undefined;
	backgroundColor?: string | undefined;
	mainTitle?: string | undefined;
	headerTitle?: string | undefined;
	username?: string | undefined;
	stepThreePassword?: string | undefined;
	transactionsMaxCount?: number | undefined;
	latestTransfersMaxCount?: number | undefined;
	transactionsByAddressMaxCount?: number | undefined;

	constructor(data?: EthernalBlockExplorerModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}