import { AutoCompleteField, Form, InputField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { BlockscoutModel } from './blockscoutFormModel';
import { Button } from 'components/Button';
import { InstanceItemType, instanceClass, instanceType } from 'data/awsData';
import { useInstanceTypeDescriptionCallback } from 'feature/Network/networkHook';

type Props = {
	configBlockscout?: BlockscoutModel
	onSave: (newConfigBlockscout: BlockscoutModel) => void
	cancel(): void
	onAdvancedConfigurationClick(): void
}

export const BlockscountForm = ({ configBlockscout, onSave, cancel, onAdvancedConfigurationClick }: Props) => {
	const [values, setValues] = useState(configBlockscout || new BlockscoutModel());

	const getDescription = useInstanceTypeDescriptionCallback(instanceType);

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<InputField
								id={propertyOf<BlockscoutModel>('name')}
								label='Name'
								isRequired
							/>
							<SmartContainer>
								<SmartItem size='small'>
									<AutoCompleteField
										id={propertyOf<BlockscoutModel>('instanceClassId')}
										label='Instance Class'
										items={instanceClass}
										getItemId={(item: InstanceItemType) => item.id}
										getItemText={(item: InstanceItemType) => item.name}
										isRequired
									/>
									{values.instanceClassId && <small style={{color: '#6F6C6C'}}>*Compute optimized instance powered by 3rd generation AMD EPYC processors</small>}
								</SmartItem>
								<SmartItem size='small'>
									<AutoCompleteField
										id={propertyOf<BlockscoutModel>('instanceTypeId')}
										label='Instance Type'
										items={instanceType}
										getItemId={(item: InstanceItemType) => item.id}
										getItemText={(item: InstanceItemType) => item.name}
										isRequired
									/>
									{values.instanceTypeId && <small style={{color: '#6F6C6C'}}>* {getDescription(values.instanceTypeId)}</small>}
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
					<SmartContainer>
						<SmartItem>
							<SmartContainer>
								<SmartItem size='small'>
									<InputField
										id={propertyOf<BlockscoutModel>('HTTPPort')}
										label='HTTP Port'
										isRequired
									/>
									<InputField
										id={propertyOf<BlockscoutModel>('version')}
										label='Version'
										isRequired
									/>
									<InputField
										id={propertyOf<BlockscoutModel>('databaseKey')}
										label='Database Key Base'
										isRequired
									/>
								</SmartItem>
								<SmartItem size='small'>
									<InputField
										id={propertyOf<BlockscoutModel>('user')}
										label='User'
										isRequired
									/>
									<InputField
										id={propertyOf<BlockscoutModel>('databasePassword')}
										label='Database Password'
										type='password'
										isRequired
									/>
									<InputField
										id={propertyOf<BlockscoutModel>('masterPassword')}
										label='RDS Master Password'
										type='password'
										isRequired
									/>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
			renderAdditionalButtons={() => (
				<div style={{marginLeft: '-24px'}}>
					<Button
						text='Advanced Configuration'
						color='neutral'
						onClick={onAdvancedConfigurationClick}
					/>
				</div>
			)}
		/>
	)
}

