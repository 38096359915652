import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { BlockExplorersSubRoutesEnum } from './routes'
import { BlockExplorers } from './BlockExplorers'
import { EthernalBlockExplorerRouter } from './EthernalBlockExplorer/EthernalBlockExplorerRouter'
import { BlockscoutRouter } from './Blockscout/BlockscoutRouter'

export const BlockExplorersRouter = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route
				path={`${path}/${BlockExplorersSubRoutesEnum.BLOCKSCOUT}`}
				render={
					() => <BlockscoutRouter />
				}
			/>

			<Route
				path={`${path}/${BlockExplorersSubRoutesEnum.ETHERNAL_BLOCK_EXPLORER}`}
				render={
					() => <EthernalBlockExplorerRouter />
				}
			/>

			<Route
				render={
					() => <BlockExplorers />
				}
			/>
		</Switch>
	)
}
