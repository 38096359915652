export class BootstrapModel {
	burnContractAddress!: string;
	burnContractBalance!: string;
	contractType!: number;
	WalletType!: number;
	blocklistAddress?: string | undefined;
	proxyContractsAddress?: string | undefined;
	rewardWalletAddress?: string | undefined;
	rewardWalletBalance?: string | undefined;

	constructor(data?: BootstrapModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
