import { AccountItemIcon, CoinBagIcon, RemoveIcon } from 'components/Icons/icons'
import { AdditionalAccountsModel } from '../additionalAccountsFormModal'
import { useCallback } from 'react'
import styles from './listOfAccounts.module.scss'

type Props = {
	item: AdditionalAccountsModel
	removeAccount(id: number): void
	iconColor: string
	removeIconColor: string
	hideIcons?: boolean
}

export const Item = ({ item, removeAccount, iconColor, removeIconColor, hideIcons }: Props) => {
	const removeAccountCallback = useCallback(
		() => {
			removeAccount(item.id);
		},
		[removeAccount, item.id]
	)

	return (
		<div className={styles.content}>
			<div className={styles.item}>
				<div className={styles.row}>
					{!hideIcons &&
						<div className={styles.icon}>
							<AccountItemIcon width={18} height={18} fill={iconColor} />
						</div>
					}
					<div>
						{item.address}
					</div>
				</div>
				<div className={styles.row}>
					{!hideIcons &&
						<div className={styles.icon}>
							<CoinBagIcon width={18} height={18} fill={iconColor} />
						</div>
					}
					<div>
						{item.balance}
					</div>
				</div>
			</div>
			<div className={styles.remove}>
				<RemoveIcon width={18} height={18} fill={removeIconColor} onClick={removeAccountCallback} />
			</div>
		</div>
	)
}
