// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_container__MYkI5 {
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  background: var(--content-background);
  color: var(--content-color);
  padding: 24px;
  overflow: auto;
  position: relative;
}

.content_header__3zhP3 {
  display: flex;
  margin-bottom: 20px;
}
.content_header__3zhP3 h1 {
  font-size: 26px;
}
.content_header__3zhP3 :not(:last-child) {
  color: var(--secondary-color);
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/feature/Content/content.module.scss"],"names":[],"mappings":"AAEA;EACC,wBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,qCAAA;EACA,2BAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;AADD;;AAIA;EACC,aAAA;EACA,mBAAA;AADD;AAGC;EACC,eAAA;AADF;AAIC;EACC,6BAAA;EACA,iBAAA;AAFF","sourcesContent":["$menu_width: 50px;\n\n.container {\n\twidth: calc(100% - #{$menu_width});\n\theight: 100%;\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: center;\n\talign-items: center;\n\tflex: 1;\n\tbackground: var(--content-background);\n\tcolor: var(--content-color);\n\tpadding: 24px;\n\toverflow: auto;\n\tposition: relative;\n}\n\n.header {\n\tdisplay: flex;\n\tmargin-bottom: 20px;\n\n\th1 {\n\t\tfont-size: 26px;\n\t}\n\n\t:not(:last-child) {\n\t\tcolor: var(--secondary-color);\n\t\tmargin-right: 4px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `content_container__MYkI5`,
	"header": `content_header__3zhP3`
};
export default ___CSS_LOADER_EXPORT___;
