// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose_container__hjkPW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--content-background);
  border-radius: 5px;
  padding: 24px 32px;
  margin: 32px 0;
  cursor: pointer;
}

.choose_icon__zu6y7 {
  fill: #1B8CEA;
  vertical-align: middle;
  margin-right: 12px;
}

.choose_text__InqMS {
  color: #1B8CEA;
}`, "",{"version":3,"sources":["webpack://./src/containers/BlockExplorers/Choose/choose.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qCAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AACD;;AAEA;EACC,aAAA;EACA,sBAAA;EACA,kBAAA;AACD;;AAEA;EACC,cAAA;AACD","sourcesContent":[".container {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tbackground: var(--content-background);\n\tborder-radius: 5px;\n\tpadding: 24px 32px;\n\tmargin: 32px 0;\n\tcursor: pointer;\n}\n\n.icon {\n\tfill: #1B8CEA;\n\tvertical-align: middle;\n\tmargin-right: 12px;\n}\n\n.text {\n\tcolor: #1B8CEA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `choose_container__hjkPW`,
	"icon": `choose_icon__zu6y7`,
	"text": `choose_text__InqMS`
};
export default ___CSS_LOADER_EXPORT___;
