import { InstanceItemType } from 'data/awsData';
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

export const useNetworkMemo = (id: number) => {
	const networks = useSelector((state: RootState) => state.networks);
	return useMemo(
		() => {
			const network = networks.find(item => item.id === id);
			if (!network) {
				return;
			}

			return network;
		},
		[networks, id]
	)
}

export const useInstanceTypeDescriptionCallback = (items: InstanceItemType[]) => {
	return useCallback(
		(id: number) => {
			const description = items.find(item => item.id === id)?.description;
			return description || '';
		},
		[items]
	)
}
