import { Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { EthernalBlockExplorerModel } from '../ethernalBlockExplorerFormModel';

type Props = {
	configEthernal?: EthernalBlockExplorerModel
	onSave: (newConfigEthernal: EthernalBlockExplorerModel) => void
	cancel(): void
}

export const ConfigurationStepThreeForm = ({ configEthernal, onSave, cancel }: Props) => {
	const [values, setValues] = useState(configEthernal || new EthernalBlockExplorerModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartContainer>
								<SmartItem size='small'>
									<SmartFormGroup label='Block Explorer UI Parameters'>
										<InputField
											id={propertyOf<EthernalBlockExplorerModel>('backgroundColor')}
											label='Background Color'
											isRequired
										/>
										<InputField
											id={propertyOf<EthernalBlockExplorerModel>('headerTitle')}
											label='Header Title'
											isRequired
										/>
										<InputField
											id={propertyOf<EthernalBlockExplorerModel>('username')}
											label='Username'
											isRequired
										/>
										<InputNumberField
											id={propertyOf<EthernalBlockExplorerModel>('transactionsMaxCount')}
											label='Transactions Max Count'
											isRequired
										/>
										<InputNumberField
											id={propertyOf<EthernalBlockExplorerModel>('latestTransfersMaxCount')}
											label='NFT Latest Transfers Max Count'
											isRequired
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='small'>
									<VerticalSeparator />
									<InputField
										id={propertyOf<EthernalBlockExplorerModel>('mainColor')}
										label='Main Color'
										isRequired
									/>
									<InputField
										id={propertyOf<EthernalBlockExplorerModel>('mainTitle')}
										label='Main Title'
										isRequired
									/>
									<InputField
										id={propertyOf<EthernalBlockExplorerModel>('stepThreePassword')}
										label='Password'
										isRequired
									/>
									<InputNumberField
										id={propertyOf<EthernalBlockExplorerModel>('transactionsByAddressMaxCount')}
										label='Transactions By Address Max Count'
										isRequired
									/>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

