import { useCallback, useMemo } from 'react'
import { BootstrapForm } from './BootstrapForm';
import { useHistory, useParams } from 'react-router-dom';
import { BootstrapModel } from './bootstrapFormModel';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { networkRoute } from 'containers/Network/routes';
import { bootstrapAndServiceRoute } from '../routes';

type ParamType = {
	networkId: string
}

export const Bootstrap = () => {
	const { networkId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newBootstrap: BootstrapModel) => {
			if (!network) {
				return;
			}

			const networkModel = new NetworkModel({
				...network,
				bootstrap: newBootstrap
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${bootstrapAndServiceRoute.url}/service`);
			}
		},
		[network, history, dispatch]
	);

	const initialBootstrapMemo = useMemo(
		() => {
			if (network?.bootstrap) {
				return network.bootstrap;
			}

			const model = new BootstrapModel();
			model.burnContractAddress = '0x0000000000000000000000000000000000000000';
			model.burnContractBalance = '1000000000000000000000000000000000000';
			model.WalletType = 1;
			model.contractType = 1;
			return model
		},
		[network]
	)

	return (
		<BootstrapForm
			bootstrap={initialBootstrapMemo}
			onSave={handleSaveCallback}
			cancel={handleGoBackCallback}
		/>
	)
}
