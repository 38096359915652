import { getCssVariableValue } from 'utils/cssVariablesUtils'
import { Item } from './Item'
import styles from './listOfContracts.module.scss'
import { CustomContractsModel } from '../customContractsFormModel'

type Props = {
	items: CustomContractsModel[]
	title: string
	removeContract(id: number): void
}

export const ListOfContracts = ({ items, title, removeContract }: Props) => {
	const iconColor = getCssVariableValue('--error-color');

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				{title}
			</div>
			{items.length > 0 ?
				items?.map((item) =>
					<Item
						key={item.id}
						item={item}
						removeContract={removeContract}
						iconColor={iconColor}
					/>
				)
				:
				<span className={styles.info}>You don't have any Contract uploaded.</span>
			}
		</div>

	)
}
