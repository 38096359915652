import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { AdvancedConfigurationBlockscoutStepTwoForm } from './AdvancedConfigurationBlockscoutStepTwoForm';
import { AdvancedConfigurationBlockscoutModel, BlockscoutModel } from '../../blockscoutFormModel';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { networkRoute } from 'containers/Network/routes';
import { blockscoutBlockExplorersSubRoute } from 'containers/BlockExplorers/routes';

type ParamType = {
	networkId: string
}

export const AdvancedConfigurationBlockscoutStepTwo = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (values: AdvancedConfigurationBlockscoutModel) => {
			if (!network) {
				return;
			}

			const advancedConfigurationModel = new AdvancedConfigurationBlockscoutModel({
				...network.blockscout?.advancedConfiguration,
				isStepTwo: true,
				isDisableInternalTransactions: values.isDisableInternalTransactions,
				isMixENV: values.isMixENV,
				blockscoutProtocol: values.blockscoutProtocol,
				port: values.port,
				isDisableExchangeRates: values.isDisableExchangeRates,
				poolSizeAPI: values.poolSizeAPI,
				poolSize: values.poolSize,
				isEctoUseSSL: values.isEctoUseSSL,
				heartBeatTimeout: values.heartBeatTimeout,
				memoryLimit: values.memoryLimit,
				rewardsWay: values.rewardsWay,
				emptyBlocksSanitizerBatchSize: values.emptyBlocksSanitizerBatchSize,
				isMicroserviceVerifiedEnabled: values.isMicroserviceVerifiedEnabled,
				microserviceVerifiedURL: values.microserviceVerifiedURL,
				isDisableIndexer: values.isDisableIndexer
			});


			const blockscoutModel = new BlockscoutModel({
				...network.blockscout,
				advancedConfiguration: advancedConfigurationModel
			});

			const networkModel = new NetworkModel({
				...network,
				blockscout: blockscoutModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${blockscoutBlockExplorersSubRoute.url}`);
			}
		},
		[network, history, dispatch]
	);

	const advancedConfigurationMemo = useMemo(
		() => {
			if (network?.blockscout?.advancedConfiguration?.isStepTwo) {
				return network.blockscout.advancedConfiguration;
			}

			const model = new AdvancedConfigurationBlockscoutModel({
				...network?.blockscout?.advancedConfiguration,
				isStepTwo: true,
				isDisableInternalTransactions: true,
				isMixENV: true,
				blockscoutProtocol: 'http',
				port: 400,
				isDisableExchangeRates: true,
				poolSizeAPI: 20,
				poolSize: 20,
				isEctoUseSSL: false,
				heartBeatTimeout: 60,
				memoryLimit: 10,
				rewardsWay: 'manual',
				emptyBlocksSanitizerBatchSize: 999,
				isMicroserviceVerifiedEnabled: true,
				microserviceVerifiedURL: 'http://0.0.0.0:8043/',
				isDisableIndexer: false,
			});
			return model;
		},
		[network?.blockscout]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Blockscout' subtitle='Advanced Configuration' />
			<AdvancedConfigurationBlockscoutStepTwoForm
				advancedConfiguration={advancedConfigurationMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
			/>
		</ColumnContainer>
	)
}
