export type InstanceItemType = {
	id: number,
	name: string,
	description?: string
}

export const instanceClass: InstanceItemType[] = [
	{id: 1, name: 'C6a'},
	{id: 2, name: 'C7a'}
]

export const instanceType: InstanceItemType[] = [
	{id: 1, name: 'large', description: '2 vCPU, 4 GiB'},
	{id: 2, name: 'xlarge', description: '4 vCPU, 8 GiB'},
	{id: 3, name: '2xlarge', description: '8 vCPU, 16 GiB'},
	{id: 4, name: '4xlarge', description: '16 vCPU, 32 GiB'},
	{id: 5, name: '8xlarge', description: '32 vCPU, 64 GiB'},
	{id: 6, name: '12xlarge', description: '48 vCPU, 96 GiB'},
	{id: 7, name: '16xlarge', description: '64 vCPU, 128 GiB'},
	{id: 8, name: '24xlarge', description: '96 vCPU, 192 GiB'},
	{id: 9, name: '32xlarge', description: '128 vCPU, 256 GiB'},
	{id: 10, name: '48xlarge', description: '192 vCPU, 384 GiB'},
	{id: 11, name: 'metal', description: '192 vCPU, 384 GiB'}
];