// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress_container__TxMLj {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.progress_container__TxMLj .progress_content__PmkZ8 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: -250px;
}

.progress_progress_bar__HYce0 {
  width: 250px;
  height: 10px;
  background: var(--progress-background);
  border-radius: 10px;
  border: none;
  position: relative;
  overflow: hidden;
}

.progress_progress_bar__HYce0::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
  background: var(--progress-color);
  animation: progress_fillProgress__HXuVA 1.5s ease-in-out infinite;
  animation-fill-mode: forwards;
}

@keyframes progress_fillProgress__HXuVA {
  0% {
    width: 0;
  }
  33% {
    width: 33.33%;
  }
  66% {
    width: 66.67%;
  }
  100% {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Progress/progress.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;AACD;AACC;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAGA;EACC,YAAA;EACA,YAAA;EACA,sCAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AAAD;;AAGA;EACC,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,kBAAA;EACA,iCAAA;EACA,iEAAA;EACA,6BAAA;AAAD;;AAGA;EACC;IACE,QAAA;EAAD;EAGD;IACE,aAAA;EADD;EAID;IACE,aAAA;EAFD;EAKD;IACE,WAAA;EAHD;AACF","sourcesContent":[".container {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tbottom: 0;\n\tright: 0;\n\tz-index: 10;\n\n\t.content {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\theight: 100%;\n\t\tmargin-left: -250px; // menu + submenu\n\t}\n}\n\n.progress_bar {\n\twidth: 250px;\n\theight: 10px;\n\tbackground: var(--progress-background);\n\tborder-radius: 10px;\n\tborder: none;\n\tposition: relative;\n\toverflow: hidden;\n}\n\n.progress_bar::before {\n\tcontent: \"\";\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\theight: 100%;\n\tborder-radius: 5px;\n\tbackground: var(--progress-color);\n\tanimation: fillProgress 1.5s ease-in-out infinite;\n\tanimation-fill-mode: forwards;\n}\n\n@keyframes fillProgress {\n\t0% {\n\t  width: 0;\n\t}\n\n\t33% {\n\t  width: 33.33%;\n\t}\n\n\t66% {\n\t  width: 66.67%;\n\t}\n\n\t100% {\n\t  width: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `progress_container__TxMLj`,
	"content": `progress_content__PmkZ8`,
	"progress_bar": `progress_progress_bar__HYce0`,
	"fillProgress": `progress_fillProgress__HXuVA`
};
export default ___CSS_LOADER_EXPORT___;
