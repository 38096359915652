import { PageRouter } from 'pages/PageRouter';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/store';

export const AppContainer = () => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<PageRouter />
			</BrowserRouter>
		</Provider>
	);
}
