import { TabType } from 'components/Tabs/Tabs';
import { Bootstrap } from './Bootstrap/Bootstrap';
import { Service } from './Sevice/Service';

export const bootstrapServiceTabs: TabType[] = [
	{
		id: '1',
		title: 'Bootstrap',
		route: 'bootstrap',
		component: Bootstrap,
	},
	{
		id: '2',
		title: 'Service',
		route: 'service',
		component: Service
	},
];
