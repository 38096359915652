// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listOfContracts_container__xeh51 {
  display: flex;
  flex-direction: column;
}

.listOfContracts_header__EVe45 {
  line-height: 32px;
  border-bottom: 1px solid var(--secondary-color);
  margin-bottom: 16px;
}

.listOfContracts_content__iJ0fS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.listOfContracts_content__iJ0fS .listOfContracts_item__o\\+dXJ {
  flex: 1 1;
}
.listOfContracts_content__iJ0fS .listOfContracts_remove_icon__On-X3 {
  cursor: pointer;
}
.listOfContracts_content__iJ0fS .listOfContracts_remove_icon__On-X3 svg:hover {
  fill: var(--active-color-1);
}

.listOfContracts_info__wRPwM {
  color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/containers/CustomContracts/ListOfContracts/listOfContracts.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;AACD;;AAEA;EACC,iBAAA;EACA,+CAAA;EACA,mBAAA;AACD;;AAEA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACD;AACC;EACC,SAAA;AACF;AAEC;EACC,eAAA;AAAF;AAEE;EACC,2BAAA;AAAH;;AAKA;EACC,6BAAA;AAFD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.header {\n\tline-height: 32px;\n\tborder-bottom: 1px solid var(--secondary-color);\n\tmargin-bottom: 16px;\n}\n\n.content {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tmargin-bottom: 8px;\n\n\t.item {\n\t\tflex: 1;\n\t};\n\n\t.remove_icon {\n\t\tcursor: pointer;\n\n\t\t& svg:hover {\n\t\t\tfill: var(--active-color-1);\n\t\t}\n\t}\n}\n\n.info {\n\tcolor: var(--secondary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `listOfContracts_container__xeh51`,
	"header": `listOfContracts_header__EVe45`,
	"content": `listOfContracts_content__iJ0fS`,
	"item": `listOfContracts_item__o+dXJ`,
	"remove_icon": `listOfContracts_remove_icon__On-X3`,
	"info": `listOfContracts_info__wRPwM`
};
export default ___CSS_LOADER_EXPORT___;
