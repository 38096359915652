import { RemoveIcon } from 'components/Icons/icons'
import { useCallback } from 'react'
import styles from './listOfContracts.module.scss'
import { CustomContractsModel } from '../customContractsFormModel'

type Props = {
	item: CustomContractsModel
	removeContract(id: number): void
	iconColor: string
}

export const Item = ({ item, removeContract, iconColor }: Props) => {
	const removeContractCallback = useCallback(
		() => {
			removeContract(item.id);
		},
		[removeContract, item.id]
	)

	return (
		<div className={styles.content}>
			<div className={styles.item}>
				{item.name}
			</div>
			<div className={styles.remove_icon}>
				<RemoveIcon width={18} height={18} fill={iconColor} onClick={removeContractCallback} />
			</div>
		</div>
	)
}
