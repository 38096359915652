import { LoadBalancersIcons } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const loadBalancersRoute: Route = {
	id: RootRouteEnum.LOAD_BALANCERS,
	url: RootRouteEnum.LOAD_BALANCERS,
	text: 'Load Balancers',
	Icon: LoadBalancersIcons,
}
