import { useCallback } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Login } from './Login/Login';
import { HOME_ROUTE, LOGIN_ROUTE, RedirectToLoginState } from '../PageRouter'
import { getAccessToken } from 'utils/fetchUtils';

const OnboardingRouter = () => {
    const location = useLocation();

    const isLoggedIn = useCallback(
        () => !!getAccessToken(),
        []
    )

    const shouldSwitchToHomeRoute = useCallback(
        () => isLoggedIn(),
        [isLoggedIn]
    )

    const renderLoginRoute = useCallback(
        () => {
            if (shouldSwitchToHomeRoute()) {
                return <Redirect to={HOME_ROUTE} />
            }

            return <Login state={location.state as RedirectToLoginState} />
        },
        [shouldSwitchToHomeRoute, location.state]
    )

    return (
        <Switch>
            <Route path={LOGIN_ROUTE} component={renderLoginRoute} />
        </Switch>
    )
}

export default OnboardingRouter;
