import { Cover } from 'components/Cover'
import styles from './progress.module.scss'
import { noop } from 'utils/commonHelper'

export const Progress = () => {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<Cover transparent onClick={noop}/>
				<div className={styles.progress_bar}></div>
			</div>
		</div>
	)
}
