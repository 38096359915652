import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Network } from './Network'
import { createNetworkSubRoute, viewNetworkSubRoute } from './routes'
import { CreateNetwork } from './CreateNetwork/CreateNetwork'
import { ViewNetwork } from './ViewNetwork/ViewNetwork'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

type Props = {
	hasNetwork: boolean
}

export const NetworkRouter = ({ hasNetwork }: Props) => {
	const location = useLocation();

	const networks = useSelector((state: RootState) => state.networks);

	const renderNetworkOrViewNetworkPage = useCallback(
		() => {
			const shouldRedirect = networks.length > 0 && location.pathname.split('/').length < 3;

			if (shouldRedirect) {
				return <Redirect to={viewNetworkSubRoute.url} />
			} else {
				return <Network hasNetwork={hasNetwork} />
			}
		},
		[networks.length, location.pathname, hasNetwork]
	)

	return (
		<Switch>
			<Route
				path={createNetworkSubRoute.url}
				render={
					() => <CreateNetwork />
				}
			/>

			<Route
				path={viewNetworkSubRoute.url}
				render={
					() => <ViewNetwork />
				}
			/>

			<Route render={renderNetworkOrViewNetworkPage} />
		</Switch>
	)
}
