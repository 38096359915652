import { useCallback, useState } from 'react';
import { FileInputField, Form } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Button } from 'components/Button';
import { CustomContractsModel } from './customContractsFormModel';
import { ListOfContracts } from './ListOfContracts/ListOfContracts';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	contractItems: CustomContractsModel[] | undefined
	onSave: (newContract: CustomContractsModel[]) => void
	cancel(): void
}

export const CustomContractsForm = ({ contractItems, onSave, cancel }: Props) => {
	const [values, setValues] = useState(new CustomContractsModel());
	const [contracts, setContracts] = useState(contractItems || []);

	const uploadContractCallback = useCallback(
		() => {
			if (!values.listOfContracts) {
				return;
			}

			const id = Math.floor(Math.random() * new Date().getTime());

			const newContract = new CustomContractsModel({
				id,
				name: values.listOfContracts[0].name,
				listOfContracts: values.listOfContracts
			});

			setContracts((state) => [...state, newContract]);
			setValues(new CustomContractsModel());
		},
		[values]
	);

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(contracts);
		},
		[onSave, contracts]
	)

	const handleRemoveContractCallback = useCallback(
		(id: number) => {
			const filteredContracts = contracts.filter(contract => contract.id !== id);
			setContracts(filteredContracts);
		},
		[contracts]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='small'>
							<VerticalSeparator />
							<FileInputField
								id={propertyOf<CustomContractsModel>('listOfContracts')}
								accept='application/json'
							/>
							<VerticalSeparator margin='medium' />
							<Button
								onClick={uploadContractCallback}
								text='Upload Contract'
							/>
						</SmartItem>
						<SmartItem size='small'>
							<ListOfContracts
								title='List of Contracts'
								items={contracts}
								removeContract={handleRemoveContractCallback}
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

