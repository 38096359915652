import styles from './content.module.scss'

type Props = {
	title: string
	subtitle?: string
}

export const ContentTitle = ({ title, subtitle }: Props) => {
	return (
		<div className={styles.header}>
			<h1>{title} {subtitle && '/'}</h1>
			{subtitle && <h1>{subtitle}</h1>}
		</div>
	)
}