import { useHistory } from 'react-router-dom';
import styles from './submenu.module.scss';
import { useCallback, useMemo } from 'react';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { networkRoute } from 'containers/Network/routes';

type Props = {
	url: string
	text: string
	networkId: string
	networkName: string | undefined
    isActive?: boolean
	isAccountItem: boolean
	isNetworkDeploy: boolean
}

export const Item = ({ url, text, isActive, networkId, networkName, isAccountItem, isNetworkDeploy }: Props) => {
	const history = useHistory();

	const onClickCallback = useCallback(
		() => {
			if (!networkId || url === `/${RootRouteEnum.NETWORK}`) {
				return history.push(`${networkRoute.url}`);
			}

			history.push(`${networkRoute.url}/${networkId}/${url}`);
		},
		[history, networkId, url]
	)

	const networkNameMemo = useMemo(
		() => {
			const name = networkName && text === 'My Network' ? networkName : text;
			return name;
		},
		[networkName, text]
	)

	const className = `${styles.item} ${isAccountItem ? styles.last_item : ''} ${isActive ? styles.active : ''}`;

	return (
		<div className={className} onClick={onClickCallback}>
			<span className={styles.text}>
				{networkNameMemo}
			</span>
			{isNetworkDeploy && text === 'My Network' &&
				<span className={styles.banner_live}>Live</span>
			}
		</div>
    )
}
