import { Route, Switch, useRouteMatch } from 'react-router-dom'
// import { configurationBlockscoutSubRoute } from './routes'
import { BlockscoutSubRoutesEnum, StepSubRoutesEnum } from '../routes'
import { Blockscount } from './Blockscount'
import { AdvancedConfigurationBlockscout } from './AdvancedConfigurationBlockscout/AdvancedConfigurationBlockscout'
import { AdvancedConfigurationBlockscoutStepTwo } from './AdvancedConfigurationBlockscout/StepTwo/AdvancedConfigurationBlockscoutStepTwo'

export const BlockscoutRouter = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route
				path={`${path}/${BlockscoutSubRoutesEnum.ADVANCED_CONFIGURATION}/${StepSubRoutesEnum.STEP_TWO}`}
				render={
					() => <AdvancedConfigurationBlockscoutStepTwo />
				}
			/>
			<Route
				path={`${path}/${BlockscoutSubRoutesEnum.ADVANCED_CONFIGURATION}/${StepSubRoutesEnum.STEP_ONE}`}
				render={
					() => <AdvancedConfigurationBlockscout />
				}
			/>

			<Route
				render={
					() => <Blockscount />
				}
			/>
		</Switch>
	)
}
