import React from 'react';
import styles from './button.module.scss';

interface Props {
	children?: React.ReactElement
	text?: string
	isLoading?: boolean
	disabled?: boolean
	color?: 'primary' | 'secondary' | 'neutral'
	onClick(): void
}

export const Button = ({ children, text, isLoading, disabled, color = 'primary', onClick }: Props) => {
	const btnClassName = `${styles.btn} ${styles[`btn-${color}`]} ${disabled ? styles.disabled : ''}`;

	return (
		<button
			className={btnClassName}
			onClick={onClick}
			disabled={disabled || isLoading}
		>
			{children || text}
		</button>
	)
}
