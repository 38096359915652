import { AutoCompleteField, Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AdvancedConfigurationBlockscoutModel } from '../../blockscoutFormModel';

const yesNoItems = [
	{id: true, name: 'Yes'},
	{id: false, name: 'No'},
]

type Props = {
	advancedConfiguration?: AdvancedConfigurationBlockscoutModel
	onSave: (newAdvancedConfiguration: AdvancedConfigurationBlockscoutModel) => void
	cancel(): void
}

export const AdvancedConfigurationBlockscoutStepTwoForm = ({ advancedConfiguration, onSave, cancel, }: Props) => {
	const [values, setValues] = useState(advancedConfiguration || new AdvancedConfigurationBlockscoutModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Save'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='small'>
							<AutoCompleteField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('isDisableInternalTransactions')}
								label='Indexer Disable Internal Transactions Fetcher'
								items={yesNoItems}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('isMixENV')}
								label='MIX ENV'
								items={yesNoItems}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
							<InputField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('blockscoutProtocol')}
								label='Blockscout Protocol'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('port')}
								label='Port'
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('isDisableExchangeRates')}
								label='Disable Exchange rates'
								items={yesNoItems}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
								isRequired
							/>
						</SmartItem>
						<SmartItem size='small'>
							<InputNumberField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('poolSizeAPI')}
								label='Pool Size API'
								isRequired
							/>
							<InputNumberField
								id={propertyOf<AdvancedConfigurationBlockscoutModel>('poolSize')}
								label='Pool Size'
								isRequired
							/>
							<SmartInline>
								<AutoCompleteField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('isEctoUseSSL')}
									label='Ecto use SSL'
									items={yesNoItems}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
									isRequired
								/>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('heartBeatTimeout')}
									label='Heart Beat Timeout'
									isRequired
								/>
							</SmartInline>
							<SmartInline>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('memoryLimit')}
									label='Indexer Memory Limit(Gb)'
									isRequired
								/>
								<InputField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('rewardsWay')}
									label='Fetch Rewards Way'
									isRequired
								/>
							</SmartInline>
							<SmartInline>
								<InputNumberField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('emptyBlocksSanitizerBatchSize')}
									label='Indexer Empty Blocks Sanitizer Batch Size'
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('isMicroserviceVerifiedEnabled')}
									label='Microservice SC Verified Enabled'
									items={yesNoItems}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
									isRequired
								/>
							</SmartInline>
							<SmartInline>
								<InputField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('microserviceVerifiedURL')}
									label='Microservice SC URL'
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<AdvancedConfigurationBlockscoutModel>('isDisableIndexer')}
									label='Disable Indexer'
									items={yesNoItems}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
									isRequired
								/>
							</SmartInline>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
