import { useMemo } from 'react';
import { Button } from 'components/Button';
import { RowContainer } from 'components/Layout';
import { Dialog } from 'components/Dialog';
import styles from './confirmModal.module.scss';
import deployImg from 'assets/images/deploy_architecture.png';
import { Progress } from 'components/Progress/Progress';

type Props = {
	title: string
	open: boolean
	message: string
	onConfirm(): void
	onCancel(): void
	isDeploying: boolean
}

export const ConfirmModal = ({ title, open, message, onConfirm, onCancel, isDeploying }: Props) => {
	const content = useMemo(
		() => {
			return (
				<>
					<div className={styles.content}>
						<img src={deployImg} width={'100%'} height={'100%'} alt='deploy_img' />
						<div className={styles.message}>
							{message}
						</div>
					</div>
					<div className={styles.footer}>
						<RowContainer justifyContent='center'>
							<Button
								text='Cancel'
								color='neutral'
								onClick={onCancel}
							/>
							<Button
								text='Confirm'
								onClick={onConfirm}
							/>
						</RowContainer>
					</div>
				</>
			)
		},
		[message, onCancel, onConfirm]
	)

	return (
		<>
			<Dialog
				title={title}
				size='xlarge'
				open={open}
				onClose={onCancel}
				children={content}
			/>
			{isDeploying && <Progress />}
		</>
	)
}
