import { Route } from 'feature/Routes/routeModel';
import styles from './content.module.scss';
import { ContentRouter } from './ContentRouter';

type Props = {
	rootRoutes: Route[]
	hasNetwork: boolean
}

export const ContentContainer = ({ rootRoutes, hasNetwork }: Props) => {
	return (
		<div className={styles.container}>
			<ContentRouter
				rootRoutes={rootRoutes}
				hasNetwork={hasNetwork}
			/>
		</div>
	)
}
