import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userDataSlice from './slices/userDataSlice'
import { networksReducer as networks } from 'feature/Network/reducer'


const reducer = combineReducers({
	userData: userDataSlice,
	networks,
})


export const store = configureStore({
	reducer: reducer,
	// for error in console
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
