import { Button } from 'components/Button'
import styles from './network.module.scss'
import { Link, useLocation } from 'react-router-dom';
import { NetworkSubRoutesEnum } from './routes';
import { useMemo } from 'react';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { noop } from 'utils/commonHelper';

type Props = {
	hasNetwork: boolean
}

export const Network = ({ hasNetwork }: Props) => {
	const location = useLocation();

	const pathnameMemo = useMemo(
		() => {
			if (location.pathname === '/') {
				return `${RootRouteEnum.NETWORK}/${NetworkSubRoutesEnum.CREATE}`;
			} else {
				return `${location.pathname}/${NetworkSubRoutesEnum.CREATE}`;
			}
		},
		[location.pathname]
	)

	return (
		!hasNetwork ?
			<div className={styles.container}>
				<div className={styles.img}></div>
				<div style={{marginTop: '24px'}}>
					<Link to={pathnameMemo}>
						<Button
							text='Create Network'
							onClick={noop}
						/>
					</Link>
				</div>
			</div>
			:
		<></>
	)
}
