// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submenu_container__3abE7 {
  position: relative;
  width: 200px;
  background: var(--white-color);
}

.submenu_item__bawA2 {
  height: 50.45px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.submenu_item__bawA2:first-child {
  margin-top: 74px;
}
.submenu_item__bawA2.submenu_active__96Wx6 {
  background: var(--active-color-2);
}
.submenu_item__bawA2 .submenu_text__roKDK {
  color: var(--main-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.submenu_item__bawA2.submenu_last_item__gM6OX {
  position: absolute;
  width: 200px;
  bottom: 24px;
}
.submenu_item__bawA2 .submenu_banner_live__zoqs- {
  font-size: var(--font-size-small);
  text-transform: uppercase;
  color: var(--success-color);
  padding: 3px 8px;
  border: 1px solid var(--success-color);
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Menu/Submenu/submenu.module.scss"],"names":[],"mappings":"AAIA;EACC,kBAAA;EACA,YANe;EAOf,8BAAA;AAHD;;AAMA;EACC,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAHD;AAKC;EAEC,gBAAA;AAJF;AAOC;EACC,iCAAA;AALF;AAQC;EACC,wBAAA;EAGA,uBAAA;EACA,mBAAA;EACA,gBAAA;AARF;AAWC;EACC,kBAAA;EACA,YAtCc;EAuCd,YAtCY;AA6Bd;AAYC;EACC,iCAAA;EACA,yBAAA;EACA,2BAAA;EACA,gBAAA;EACA,sCAAA;EACA,kBAAA;AAVF","sourcesContent":["$submenu_width: 200px;\n$padding_top: 24px;\n$menu_width: 50px;\n\n.container {\n\tposition: relative;\n\twidth: $submenu_width;\n\tbackground: var(--white-color);\n}\n\n.item {\n\theight: calc($menu_width + 0.45px);\n\tpadding: 15px 10px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\tcursor: pointer;\n\n\t&:first-child {\n\t\t// menu item width + menu first item padding\n\t\tmargin-top: calc($menu_width + $padding_top);\n\t}\n\n\t&.active {\n\t\tbackground: var(--active-color-2);\n\t}\n\n\t.text {\n\t\tcolor: var(--main-color);\n\n\t\t// if the name in the submenu too long...\n\t\ttext-overflow: ellipsis;\n\t\twhite-space: nowrap;\n\t\toverflow: hidden;\n\t}\n\n\t&.last_item {\n\t\tposition: absolute;\n\t\twidth: $submenu_width;\n\t\tbottom: $padding_top;\n\t}\n\n\t.banner_live {\n\t\tfont-size: var(--font-size-small);\n\t\ttext-transform: uppercase;\n\t\tcolor: var(--success-color);\n\t\tpadding: 3px 8px;\n\t\tborder: 1px solid var(--success-color);\n\t\tborder-radius: 3px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `submenu_container__3abE7`,
	"item": `submenu_item__bawA2`,
	"active": `submenu_active__96Wx6`,
	"text": `submenu_text__roKDK`,
	"last_item": `submenu_last_item__gM6OX`,
	"banner_live": `submenu_banner_live__zoqs-`
};
export default ___CSS_LOADER_EXPORT___;
