
export const NetworkDetails = () => {
	return (
		<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
			<p style={{marginBottom: '12px'}}><b>Standard Blockchain Service</b> - Deploy blockchain nodes in your own secure multi-cloud hosting environment</p>
			<p style={{marginBottom: '12px'}}><b>Ethereum</b> - Create a permissioned blockchain network tailored to the Enterprise with efficient consensus and privacy extensions. Benefit from resources and tooling developed by the vibrant Ethereum community</p>
			<p style={{marginBottom: '12px'}}><b>Polygon Edge</b> - Open source Ethereum-compatibile chain, powered by Polygon comunity</p>
			<p style={{marginBottom: '12px'}}><b>Consensus Algorithm</b> - Proof of Stake</p>
		</div>
	)
}
