// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiCheckbox_container__SbRHI {
  height: 32px;
  display: flex;
  align-items: center;
}

.multiCheckbox_multi_checkbox_item__J4JHh + .multiCheckbox_multi_checkbox_item__J4JHh {
  margin-left: 24px;
}

.multiCheckbox_container_column__9fmE9 {
  display: flex;
  flex-direction: column;
}

.multiCheckbox_multi_checkbox_item_column__2K8J9 {
  height: 26px;
}

.multiCheckbox_multi_checkbox_item_column__2K8J9 + .multiCheckbox_multi_checkbox_item_column__2K8J9 {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/controls/MultiCheckbox/multiCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACG,aAAA;EACA,mBAAA;AACJ;;AAEA;EACC,iBAAA;AACD;;AAEA;EACI,aAAA;EACH,sBAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,gBAAA;AACD","sourcesContent":[".container {\n\theight: 32px;\n    display: flex;\n    align-items: center;\n}\n\n.multi_checkbox_item + .multi_checkbox_item {\n\tmargin-left: 24px;\n}\n\n.container_column {\n    display: flex;\n\tflex-direction: column;\n}\n\n.multi_checkbox_item_column {\n\theight: 26px;\n}\n\n.multi_checkbox_item_column + .multi_checkbox_item_column {\n\tmargin-top: 10px;\n}\n\n// .multi_checkbox:disabled + .label {\n// \tcolor: #a9a9a9;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `multiCheckbox_container__SbRHI`,
	"multi_checkbox_item": `multiCheckbox_multi_checkbox_item__J4JHh`,
	"container_column": `multiCheckbox_container_column__9fmE9`,
	"multi_checkbox_item_column": `multiCheckbox_multi_checkbox_item_column__2K8J9`
};
export default ___CSS_LOADER_EXPORT___;
