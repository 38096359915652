import { Route, Switch } from 'react-router-dom';
import { networkRoute } from 'containers/Network/routes';
import { HomePage } from './HomePage';

export const HomeRouter = () => {
	return (
		<Switch>
			<Route
				path={[
					`${networkRoute.url}/:networkId`,
					networkRoute.url
				]}
				render={
					() => <HomePage />
				}
			/>

			<Route
				render={() => <HomePage />}
			/>
		</Switch>
	)
}
