import { ColumnContainer } from 'components/Layout'
import { ContentTitle } from 'feature/Content/ContentTitle'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BoxItems } from 'containers/MyNetwork/BoxItems'
import { LoadBalancersForm } from './LoadBalancersForm'
import { EditSubRoutesEnum } from 'feature/Routes/RootRouteEnum'
import { useNetworkMemo } from 'feature/Network/networkHook'
import { ConfigurationModel, SimpleModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel'
import { networkRoute } from 'containers/Network/routes'
import { myNetworkRoute } from 'containers/MyNetwork/routes'
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService'
import { NetworkModel } from 'feature/Network/NetworkModel'
import { useDispatch } from 'react-redux'
import { blockExplorersRoute } from 'containers/BlockExplorers/routes'

type ParamType = {
	networkId: string
}

export const LoadBalancers = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const onClickCallback = useCallback(
		(id: string) => {
			history.push(`${history.location.pathname}/${EditSubRoutesEnum.Edit}/${id}`);
		},
		[history]
	)

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (values: SimpleModel) => {
			if (!network) {
				return;
			}

			const id = Math.floor(Math.random() * new Date().getTime());

			const newLoadBalancer = new SimpleModel({
				id: id,
				name: values.name,
				loadBalancersTypeId: values.loadBalancersTypeId,
				loadBalancersValidators: values.loadBalancersValidators,
				loadBalancersFullNodes: values.loadBalancersFullNodes
			});

			const configurationModel = new ConfigurationModel({
				...network.configuration,
				loadBalancers: [...network.configuration?.loadBalancers!, newLoadBalancer],
			});

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel,
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[history, dispatch, network]
	);

	const loadBalancersContentMemo = useMemo(
		() => {
			return network && [network].map((item) => (
				<BoxItems
					key={item.id}
					items={item.configuration?.loadBalancers}
					onClick={onClickCallback}
					hideViewAllButton
				/>
			));
		},
		[onClickCallback, network]
	)

	const handleGoToBlockExplorersCallback = useCallback(
		() => history.push(blockExplorersRoute.url),
		[history]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Load Balancers' />
			<LoadBalancersForm
				loadBalancersContent={loadBalancersContentMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
				goToBlockExplorers={handleGoToBlockExplorersCallback}
			/>
		</ColumnContainer>
	)
}

