import configImg from 'assets/images/config_img.png'

type Props = {
	showInfoNote?: boolean
}

export const ConfigurationDetails = ({ showInfoNote }: Props) => {
	return (
		<>
			<div style={{marginTop: '-96px', marginBottom: '24px'}}>
				<img src={configImg} width={'380px'} height={'100%'} alt='logo'/>
			</div>
			{showInfoNote &&
				<div>
					* Amazon EC2 C6a instances are compute optimized instances powered by third generation AMD EPYC processors and are designed for compute-intensive workloads
				</div>
			}
		</>
	)
}

