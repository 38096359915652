import { AutoCompleteField, Form, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { ConfigurationModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { InstanceItemType, instanceClass, instanceType } from 'data/awsData';
import { useInstanceTypeDescriptionCallback } from 'feature/Network/networkHook';

type Props = {
	configFullNodes: ConfigurationModel | undefined
	onSave: (newConfigFullNodes: ConfigurationModel) => void
	cancel(): void
	fullNodesContent: JSX.Element[] | undefined
}

export const FullNodesForm = ({ configFullNodes, onSave, cancel, fullNodesContent }: Props) => {
	const [values, setValues] = useState(configFullNodes || new ConfigurationModel());

	const getDescription = useInstanceTypeDescriptionCallback(instanceType);

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Save'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='xsmall'>
							{fullNodesContent}
						</SmartItem>
						<SmartItem size='xsmall'>
							<InputNumberField
								id={propertyOf<ConfigurationModel>('fullNodesNumber')}
								label='Number'
							/>
							<AutoCompleteField
								id={propertyOf<ConfigurationModel>('instanceClassId')}
								label='Instance Class'
								items={instanceClass}
								getItemId={(item: InstanceItemType) => item.id}
								getItemText={(item: InstanceItemType) => item.name}
							/>
							{values.instanceClassId && <small style={{color: '#6F6C6C'}}>* Compute optimized instance powered by 3rd generation AMD EPYC processors</small>}
							<AutoCompleteField
								id={propertyOf<ConfigurationModel>('fullNodesInstanceTypeId')}
								label='Instance Type'
								items={instanceType}
								getItemId={(item: InstanceItemType) => item.id}
								getItemText={(item: InstanceItemType) => item.name}
							/>
							{values.fullNodesInstanceTypeId && <small style={{color: '#6F6C6C'}}>* {getDescription(values.fullNodesInstanceTypeId)}</small>}
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

