import { AutoCompleteField, Form, InputField, } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { BootstrapModel } from './bootstrapFormModel';

type Props = {
	bootstrap?: BootstrapModel
	onSave: (newBootstrap: BootstrapModel) => void
	cancel(): void
}

export const BootstrapForm = ({ bootstrap, onSave, cancel }: Props) => {
	const [values, setValues] = useState(bootstrap || new BootstrapModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<InputField
								id={propertyOf<BootstrapModel>('blocklistAddress')}
								label='Blocklist Administrator Address'
								isRequired
							/>
							<InputField
								id={propertyOf<BootstrapModel>('proxyContractsAddress')}
								label='Proxy Contracts Administrator Address'
								isRequired
							/>
							<VerticalSeparator margin='medium'/>
							<InputField
								id={propertyOf<BootstrapModel>('rewardWalletAddress')}
								label='Reward Wallet Address'
								isRequired
							/>
							<SmartInline>
								<InputField
									id={propertyOf<BootstrapModel>('rewardWalletBalance')}
									label='Reward Wallet Balance '
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<BootstrapModel>('WalletType')}
									label='Type'
									items={[{id: 1, name: 'WEI'}]}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
									disabled
									isRequired
								/>
							</SmartInline>
						</SmartItem>
						<SmartItem>
							<InputField
								id={propertyOf<BootstrapModel>('burnContractAddress')}
								label='Burn Contract Address'
								isRequired
							/>
							<VerticalSeparator margin='small'/>
							<SmartInline>
								<InputField
									id={propertyOf<BootstrapModel>('burnContractBalance')}
									label='Burn Contract Balance'
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<BootstrapModel>('contractType')}
									label='Type'
									items={[{id: 1, name: 'WEI'}]}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
									disabled
									isRequired
								/>
							</SmartInline>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
