// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover_container__aehCU {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.55);
}

.cover_transparent__gh4Pk {
  background: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/Cover/cover.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,UAAA;EACA,+BAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".container {\n\tposition: fixed;\n\ttop: 0;\n\tright: 0;\n\tbottom: 0;\n\tleft: 0;\n\tz-index: 1;\n\tbackground: rgba($color: #000, $alpha: 0.55);\n}\n\n.transparent {\n\tbackground: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cover_container__aehCU`,
	"transparent": `cover_transparent__gh4Pk`
};
export default ___CSS_LOADER_EXPORT___;
