import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { EditLoadBalancers } from './Edit/EditLoadBalancers'
import { LoadBalancers } from './LoadBalancers'
import { EditSubRoutesEnum } from 'feature/Routes/RootRouteEnum'

export const LoadBalancersRouter = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${EditSubRoutesEnum.Edit}/:loadBalancerId`}
				render={
					() => <EditLoadBalancers />
				}
			/>

			<Route
				render={
					() => <LoadBalancers />
				}
			/>
		</Switch>
	)
}
