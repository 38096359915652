export class BlockscoutModel {
	name?: string | undefined;
	instanceClassId?: number | undefined;
	instanceTypeId?: number | undefined;
	HTTPPort?: string | undefined;
	version?: string | undefined;
	databaseKey?: string | undefined;
	user?: string | undefined;
	databasePassword?: string | undefined;
	masterPassword?: string | undefined;
	advancedConfiguration?: AdvancedConfigurationBlockscoutModel | undefined

	constructor(data?: BlockscoutModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}

export class AdvancedConfigurationBlockscoutModel {
	databaseURL?: string | undefined;
	subNetwork?: string | undefined;
	coin?: string | undefined;
	chainId?: Number | undefined;
	heartCommand?: string | undefined;
	secretKeyBase?: string | undefined;
	coinName?: string | undefined;
	isDisableBlockReward?: boolean | undefined;
	isDisablePendingTransactions?: boolean | undefined;
	isStepTwo?: boolean | undefined;
	isDisableInternalTransactions?: boolean | undefined;
	isDisableExchangeRates?: boolean | undefined;
	isMixENV?: boolean | undefined;
	blockscoutProtocol?: string | undefined;
	port?: number | undefined;
	poolSizeAPI?: number | undefined;
	poolSize?: number | undefined;
	isEctoUseSSL?: boolean | undefined;
	memoryLimit?: number | undefined;
	emptyBlocksSanitizerBatchSize?: number | undefined;
	microserviceVerifiedURL?: string | undefined;
	isMicroserviceVerifiedEnabled?: boolean | undefined;
	isDisableIndexer?: boolean | undefined;
	heartBeatTimeout?: number | undefined;
	rewardsWay?: string | undefined;

	constructor(data?: AdvancedConfigurationBlockscoutModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
