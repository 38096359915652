import { AccountModel } from 'containers/Account/profileFormModel';
import { AdditionalAccountsModel } from 'containers/AdditionalAccounts/additionalAccountsFormModal';
import { BlockscoutModel } from 'containers/BlockExplorers/Blockscout/blockscoutFormModel';
import { EthernalBlockExplorerModel } from 'containers/BlockExplorers/EthernalBlockExplorer/ethernalBlockExplorerFormModel';
import { BootstrapModel } from 'containers/BootstrapAndService/Bootstrap/bootstrapFormModel';
import { ServiceModel } from 'containers/BootstrapAndService/Sevice/serviceFormModel';
import { CustomContractsModel } from 'containers/CustomContracts/customContractsFormModel';
import { ConfigurationModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel';
import { DetailsModel } from 'containers/Network/CreateNetwork/networkFormModel';
import { PolygonEdgeModel } from 'containers/PolygonEdge/polygonEdgeFormModel';

export class NetworkModel {
	id?: number // TODO: kada se kreira network, u bazi ovaj id bude prazan dokle god ne pozove update network-a opet
	details?: DetailsModel | undefined
	configuration?: ConfigurationModel | undefined
	polygonEdge?: PolygonEdgeModel | undefined
	bootstrap?: BootstrapModel | undefined
	service?: ServiceModel | undefined
	blockscout?: BlockscoutModel | undefined
	ethernal?: EthernalBlockExplorerModel | undefined
	additionalAccounts?: AdditionalAccountsModel[] | undefined
	customContracts?: CustomContractsModel[] | undefined
	account?: AccountModel | undefined
	// helper flags for main menu generation
	isInitialized!: boolean
	isPolygonEdgeInitialized!: boolean
	isBootstrapAndServiceInitialized!: boolean
	isBlockExplorersInitialized!: boolean
	isDeploy!: boolean

	constructor(data?: NetworkModel) {
		if (data) {
			this.id = data.id;
			this.details = data.details;
			this.configuration = data.configuration;
			this.polygonEdge = data.polygonEdge;
			this.bootstrap = data.bootstrap;
			this.service = data.service;
			this.blockscout = data.blockscout;
			this.ethernal = data.ethernal;
			this.additionalAccounts = data.additionalAccounts;
			this.customContracts = data.customContracts;
			this.account = data.account;
			this.isInitialized = data.isInitialized;
			this.isPolygonEdgeInitialized = data.isPolygonEdgeInitialized;
			this.isBootstrapAndServiceInitialized = data.isBootstrapAndServiceInitialized;
			this.isBlockExplorersInitialized = data.isBlockExplorersInitialized;
			this.isDeploy = data.isDeploy;
		}
	}
}
