// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myNetwork_container__wmEwG {
  display: flex;
  flex-wrap: wrap;
}
.myNetwork_container__wmEwG > div {
  margin-right: 24px;
  margin-bottom: 24px;
}

.myNetwork_content__dQQtW {
  position: relative;
  background-color: var(--field-background);
  padding: 32px;
  border-radius: 5px;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);
  min-width: 260px;
  min-height: 380px;
}
.myNetwork_content__dQQtW .myNetwork_item__FI5eu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--separator-color);
}
.myNetwork_content__dQQtW .myNetwork_button__nu-Hs {
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.myNetwork_create__D4MD- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.myNetwork_create__D4MD- .myNetwork_text__lwfD8 {
  text-align: center;
}
.myNetwork_create__D4MD- button {
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/containers/MyNetwork/myNetwork.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;AACD;AACC;EACC,kBAAA;EACA,mBAAA;AACF;;AAGA;EACC,kBAAA;EACA,yCAAA;EACA,aAAA;EACA,kBAAA;EACA,8CAAA;EACA,gBAAA;EACA,iBAAA;AAAD;AAEC;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,+CAAA;AAAF;AAGC;EACC,kBAAA;EACA,YAAA;EACA,QAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;AADF;;AAKA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAFD;AAIC;EACC,kBAAA;AAFF;AAKC;EACC,gBAAA;AAHF","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\n\t& > div {\n\t\tmargin-right: 24px;\n\t\tmargin-bottom: 24px;\n\t}\n}\n\n.content {\n\tposition: relative;\n\tbackground-color: var(--field-background);\n\tpadding: 32px;\n\tborder-radius: 5px;\n\tbox-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);\n\tmin-width: 260px;\n\tmin-height: 380px;\n\n\t.item {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tmargin-bottom: 16px;\n\t\tborder-bottom: 1px solid var(--separator-color);\n\t}\n\n\t.button {\n\t\tposition: absolute;\n\t\tbottom: 16px;\n\t\tright: 0;\n\t\tleft: 0;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t}\n}\n\n.create {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 100%;\n\n\t.text {\n\t\ttext-align: center;\n\t}\n\n\tbutton {\n\t\tmargin-top: 24px;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `myNetwork_container__wmEwG`,
	"content": `myNetwork_content__dQQtW`,
	"item": `myNetwork_item__FI5eu`,
	"button": `myNetwork_button__nu-Hs`,
	"create": `myNetwork_create__D4MD-`,
	"text": `myNetwork_text__lwfD8`
};
export default ___CSS_LOADER_EXPORT___;
