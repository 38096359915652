export { ReactComponent as NetworkIcon } from 'assets/icons/logo.svg';
export { ReactComponent as MyNetworkIcon } from 'assets/icons/network.svg';
export { ReactComponent as PolygonEdgeIcon } from 'assets/icons/polygon_edge.svg';
export { ReactComponent as BootstrapAndServiceIcon } from 'assets/icons/bootstrap_services.svg';
export { ReactComponent as ValidatorsIcon } from 'assets/icons/user_check_validate.svg';
export { ReactComponent as FullNodesIcon } from 'assets/icons/full_nodes.svg';
export { ReactComponent as LoadBalancersIcons } from 'assets/icons/load_balancer.svg';
export { ReactComponent as BlockExplorersIcons } from 'assets/icons/mdi_webpack.svg';
export { ReactComponent as AdditionalAccountsIcons } from 'assets/icons/mdi_ethereum.svg';
export { ReactComponent as CustomContractsIcons } from 'assets/icons/custom_contract.svg';
export { ReactComponent as AccountIcon } from 'assets/icons/profile_circle.svg';
export { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down.svg';
export { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
export { ReactComponent as InfoSquaredIcon } from 'assets/icons/info_squared.svg';
export { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
export { ReactComponent as NextIcon } from 'assets/icons/next.svg';
export { ReactComponent as BlockscoutIcon } from 'assets/icons/blockscout.svg';
export { ReactComponent as EthernalBlockIcon } from 'assets/icons/ethernal.svg';
export { ReactComponent as AccountItemIcon } from 'assets/icons/account_item.svg';
export { ReactComponent as CoinBagIcon } from 'assets/icons/coin_bag.svg';
export { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
export { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

export type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
}>