import { AutoCompleteField, Form, InputField, MultiCheckboxField, } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { SimpleModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel';

type Props = {
	loadBalancersContent: JSX.Element[] | undefined
	onSave: (newConfigLoadBalancers: SimpleModel) => void
	cancel(): void
	goToBlockExplorers(): void
}

export const LoadBalancersForm = ({ onSave, cancel, goToBlockExplorers, loadBalancersContent }: Props) => {
	const [values, setValues] = useState(new SimpleModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Create'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='xsmall'>
							{loadBalancersContent}
						</SmartItem>
						<SmartItem>
							<SmartInline>
								<InputField
									id={propertyOf<SimpleModel>('name')}
									label='Name'
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<SimpleModel>('loadBalancersTypeId')}
									label='Type'
									items={[{id: 1, name: 'Application'}, {id: 2, name: 'Network'}]}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.name}
									isRequired
								/>
							</SmartInline>
							<VerticalSeparator />
							<div style={{fontWeight: 'bold'}}>Select resources to connect with:</div>
							<VerticalSeparator margin='small'/>
							<SmartContainer>
								<SmartItem size='xxsmall'>
									<SmartFormGroup label='Validators'>
										<MultiCheckboxField
											id={propertyOf<SimpleModel>('loadBalancersValidators')}
											items={[
												{id: 1, text: 'validator-001'},
												{id: 2, text: 'validator-002'},
												{id: 3, text: 'validator-003'},
												{id: 4, text: 'validator-004'},
											]}
											column
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='xxsmall'>
									<SmartFormGroup label='Full Nodes'>
										<MultiCheckboxField
											id={propertyOf<SimpleModel>('loadBalancersFullNodes')}
											items={[
												{id: 1, text: 'fullnode-001'},
												{id: 2, text: 'fullnode-002'},
												{id: 3, text: 'fullnode-003'},
												{id: 4, text: 'fullnode-004'},
											]}
											column
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem>
									<SmartFormGroup label='Block Explorers'>
										<span style={{color: '#6F6C6C', cursor: 'pointer'}} onClick={goToBlockExplorers}>You don't have any <br /> Block Explorer created.</span>
									</SmartFormGroup>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
