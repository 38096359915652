import { Button } from 'components/Button'
import { useCallback, useState } from 'react'
import styles from './myNetwork.module.scss'
import { EditModal } from './EditModal/EditModal'
import { SimpleModel } from './ConfigurationMyNetwork/myNetworkFormModel'

type Props = {
	item: SimpleModel
	onClick?(id: string): void
	disabled?: boolean
	onEditSave?(values: SimpleModel): Promise<void>
	isValidatorsOrFullNodes?: 'validator' | 'fullnode'
}

export const Item = ({ item, onClick, disabled, onEditSave, isValidatorsOrFullNodes }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const onClickCallback = useCallback(
		() => {
			if (onClick) {
				onClick(item.id.toString());
			} else {
				setIsModalOpen(true);
			}
		},
		[onClick, item.id]
	)

	const closeModalCallback = useCallback(
		() => setIsModalOpen(false),
		[]
	);

	return (
		<div className={styles.item}>
			<div>
				<p>{item.name}</p>
			</div>
			<div style={{marginTop: '-12px', marginRight: '-24px'}}>
				<Button
					text='edit'
					color='neutral'
					onClick={onClickCallback}
					disabled={disabled}
				/>
			</div>
			{!!isValidatorsOrFullNodes &&
				<EditModal
					item={item}
					isOpen={isModalOpen}
					close={closeModalCallback}
					isValidatorsOrFullNodes={isValidatorsOrFullNodes}
					onEditSave={onEditSave}
				/>
			}
		</div>
	)
}
