// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_btn__YVOtd {
  position: relative;
  display: inline-block;
  font-size: var(--font-size-button);
  padding: 12px 24px;
  margin: 0;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.button_btn-primary__mi5KF {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.button_btn-secondary__pSX4o {
  color: var(--white-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.button_btn-neutral__7B8P8 {
  color: var(--secondary-color);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.button_btn-primary__mi5KF:hover:not(:disabled), .button_btn-secondary__pSX4o:hover:not(:disabled) {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.button_btn__YVOtd.button_disabled__RVTMj, .button_btn__YVOtd:disabled {
  cursor: not-allowed;
  opacity: 0.55;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/button.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACG,qBAAA;EACA,kCAAA;EACA,kBAAA;EACH,SAAA;EACA,kBAAA;EACG,kBAAA;EACA,sBAAA;EACA,6BAAA;EACH,8CAAA;EACA,gCAAA;EACA,eAAA;AACD;;AAEA;EACC,yBAAA;EACA,sCAAA;EACA,kCAAA;AACD;;AAEA;EACC,yBAAA;EACA,wCAAA;EACA,oCAAA;AACD;;AAEA;EACC,6BAAA;EACA,6BAAA;EACA,yBAAA;EACA,gBAAA;AACD;;AAIE;EACC,4CAAA;AADH;;AAMA;EACC,mBAAA;EACA,aAAA;AAHD","sourcesContent":[".btn {\n\tposition: relative;\n    display: inline-block;\n    font-size: var(--font-size-button);\n    padding: 12px 24px;\n\tmargin: 0;\n\tborder-radius: 5px;\n    text-align: center;\n    vertical-align: middle;\n    border: 1px solid transparent;\n\tbox-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);\n\ttransition: all 0.3s ease-in-out;\n\tcursor: pointer;\n}\n\n.btn-primary {\n\tcolor: var(--white-color);\n\tbackground-color: var(--primary-color);\n\tborder-color: var(--primary-color);\n}\n\n.btn-secondary {\n\tcolor: var(--white-color);\n\tbackground-color: var(--secondary-color);\n\tborder-color: var(--secondary-color);\n}\n\n.btn-neutral {\n\tcolor: var(--secondary-color);\n\tbackground-color: transparent;\n\tborder-color: transparent;\n\tbox-shadow: none;\n}\n\n.btn-primary, .btn-secondary {\n\t&:hover {\n\t\t&:not(:disabled) {\n\t\t\tbox-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);\n\t\t}\n\t}\n}\n\n.btn.disabled, .btn:disabled {\n\tcursor: not-allowed;\n\topacity: 0.55;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `button_btn__YVOtd`,
	"btn-primary": `button_btn-primary__mi5KF`,
	"btn-secondary": `button_btn-secondary__pSX4o`,
	"btn-neutral": `button_btn-neutral__7B8P8`,
	"disabled": `button_disabled__RVTMj`
};
export default ___CSS_LOADER_EXPORT___;
