import { AutoCompleteField, Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { EthernalBlockExplorerModel } from './ethernalBlockExplorerFormModel';
import { InstanceItemType, instanceClass, instanceType } from 'data/awsData';
import { useInstanceTypeDescriptionCallback } from 'feature/Network/networkHook';

type Props = {
	configEthernal?: EthernalBlockExplorerModel
	onSave: (newConfigEthernal: EthernalBlockExplorerModel) => void
	cancel(): void
}

export const EthernalBlockExplorerForm = ({ configEthernal, onSave, cancel }: Props) => {
	const [values, setValues] = useState(configEthernal || new EthernalBlockExplorerModel());

	const getDescription = useInstanceTypeDescriptionCallback(instanceType);

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Next'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<InputField
								id={propertyOf<EthernalBlockExplorerModel>('name')}
								label='Name'
								isRequired
							/>
							<SmartContainer>
								<SmartItem size='small'>
									<AutoCompleteField
										id={propertyOf<EthernalBlockExplorerModel>('instanceClassId')}
										label='Instance Class'
										items={instanceClass}
										getItemId={(item: InstanceItemType) => item.id}
										getItemText={(item: InstanceItemType) => item.name}
										isRequired
									/>
									{values.instanceClassId && <small style={{color: '#6F6C6C'}}>*Compute optimized instance powered by 3rd generation AMD EPYC processors</small>}
								</SmartItem>
								<SmartItem size='small'>
									<AutoCompleteField
										id={propertyOf<EthernalBlockExplorerModel>('instanceTypeId')}
										label='Instance Type'
										items={instanceType}
										getItemId={(item: InstanceItemType) => item.id}
										getItemText={(item: InstanceItemType) => item.name}
										isRequired
									/>
									{values.instanceTypeId && <small style={{color: '#6F6C6C'}}>* {getDescription(values.instanceTypeId)}</small>}
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
					<SmartContainer>
						<SmartItem>
							<SmartContainer>
								<SmartItem size='small'>
									<SmartFormGroup label='Database Parameters'>
										<InputField
											id={propertyOf<EthernalBlockExplorerModel>('databaseName')}
											label='Name'
											isRequired
										/>
										<InputField
											id={propertyOf<EthernalBlockExplorerModel>('host')}
											label='Host'
											isRequired
										/>
										<InputNumberField
											id={propertyOf<EthernalBlockExplorerModel>('port')}
											label='Port'
											isRequired
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='small'>
									<VerticalSeparator margin='large'/>
									<InputField
										id={propertyOf<EthernalBlockExplorerModel>('password')}
										label='Password'
										isRequired
									/>
									<InputField
										id={propertyOf<EthernalBlockExplorerModel>('user')}
										label='User'
										isRequired
									/>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

