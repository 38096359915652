import { ColumnContainer } from 'components/Layout'
import { ContentTitle } from 'feature/Content/ContentTitle'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AdditionalAccountsModel } from './additionalAccountsFormModal'
import { AdditionalAccountsForm } from './AdditionalAccountsForm'
import { useDispatch } from 'react-redux'
import { useNetworkMemo } from 'feature/Network/networkHook'
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService'
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { networkRoute } from 'containers/Network/routes'
import { myNetworkRoute } from 'containers/MyNetwork/routes'
import { NetworkModel } from 'feature/Network/NetworkModel'

type ParamType = {
	networkId: string
}

export const AdditionalAccounts = () => {
	const { networkId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (additionalAccount: AdditionalAccountsModel) => {
			if (!network) {
				return;
			}

			const networkModel = new NetworkModel({
				...network,
				additionalAccounts: additionalAccount.accountItems
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[network, history, dispatch]
	);

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Additional Accounts' />
				<AdditionalAccountsForm
					onSave={handleSaveCallback}
					cancel={handleGoBackCallback}
					additionalAccountsItems={network?.additionalAccounts}
				/>
		</ColumnContainer>
	)
}
