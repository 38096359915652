// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_container__f7yqM {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50px;
  background-color: var(--main-color);
  padding: 24px 0;
}

.menu_item__X9-5v {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.menu_item__X9-5v.menu_active__ohKIr {
  background: var(--active-color-1);
}
.menu_item__X9-5v:not(:first-child) {
  padding: 15px 10px;
}
.menu_item__X9-5v.menu_last_item__ZDFhA {
  position: absolute;
  width: 50px;
  bottom: 24px;
}
.menu_item__X9-5v svg {
  fill: var(--white-color);
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/containers/Menu/menu.module.scss"],"names":[],"mappings":"AAGA;EACC,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAPY;EAQZ,mCAAA;EACA,eAAA;AAFD;;AAKA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;AAFD;AAIC;EACC,iCAAA;AAFF;AAKC;EACC,kBAAA;AAHF;AAMC;EACC,kBAAA;EACA,WA7BW;EA8BX,YA7BY;AAyBd;AAOC;EACC,wBAAA;EACA,sBAAA;AALF","sourcesContent":["$menu_width: 50px;\n$padding_top: 24px;\n\n.container {\n\tposition: relative;\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: $menu_width;\n\tbackground-color: var(--main-color);\n\tpadding: $padding_top 0;\n}\n\n.item {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 10px;\n\tcursor: pointer;\n\n\t&.active {\n\t\tbackground: var(--active-color-1);\n\t}\n\n\t&:not(:first-child) {\n\t\tpadding: 15px 10px;\n\t}\n\n\t&.last_item {\n\t\tposition: absolute;\n\t\twidth: $menu_width;\n\t\tbottom: $padding_top;\n\t}\n\n\tsvg {\n\t\tfill: var(--white-color);\n\t\tvertical-align: middle;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `menu_container__f7yqM`,
	"item": `menu_item__X9-5v`,
	"active": `menu_active__ohKIr`,
	"last_item": `menu_last_item__ZDFhA`
};
export default ___CSS_LOADER_EXPORT___;
