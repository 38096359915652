import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EditLoadBalancersForm } from './EditLoadBalancersForm';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { ConfigurationModel, SimpleModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { useDispatch } from 'react-redux';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { networkRoute } from 'containers/Network/routes';
import { myNetworkRoute } from 'containers/MyNetwork/routes';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { blockExplorersRoute } from 'containers/BlockExplorers/routes';

type ParamType = {
	networkId: string
	loadBalancerId: string
}

export const EditLoadBalancers = () => {
	const { networkId, loadBalancerId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (values: SimpleModel) => {
			if (!network) {
				return;
			}

			const newLoadBalancers = network.configuration?.loadBalancers?.map(item => {
				if (item.id === values.id) {
					return new SimpleModel({
						...item,
						loadBalancersValidators: values.loadBalancersValidators,
						loadBalancersFullNodes: values.loadBalancersFullNodes
					});
				}

				return item;
			});

			const configurationModel = new ConfigurationModel({
				...network.configuration,
				loadBalancers: newLoadBalancers
			})

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[history, dispatch, network]
	);

	const loadBalancerMemo = useMemo(
		() => {
			if (network && network.configuration) {
				const loadBalancer = network.configuration.loadBalancers?.find(item => item.id === parseInt(loadBalancerId));
				return loadBalancer;
			}
		},
		[network, loadBalancerId]
	)

	const handleGoToBlockExplorersCallback = useCallback(
		() => history.push(`${networkRoute.url}/${networkId}/${blockExplorersRoute.url}`),
		[history, networkId]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Load Balancers' subtitle={`${loadBalancerMemo?.name || ''}`} />
			<EditLoadBalancersForm
				configLoadBalancer={loadBalancerMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
				goToBlockExplorers={handleGoToBlockExplorersCallback}
			/>
		</ColumnContainer>
	)
}
