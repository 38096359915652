export class AdditionalAccountsModel {
	id!: number;
	address?: string | undefined;
	balance?: string | undefined;
	type?: number | undefined;
	accountItems?:  AdditionalAccountsModel[] | undefined;

	constructor(data?: AdditionalAccountsModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
