import { useCallback } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { getAccessToken } from 'utils/fetchUtils';
import OnboardingPage from './Onboarding/OnboardingPage';
import { HomeRouter } from './Home/HomeRouter';

export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'

export type RedirectToLoginState = {
	from?: string
}

export const PageRouter = () => {
	const location = useLocation();

	const isLoggedIn = useCallback(
		() => !!getAccessToken(),
		[]
	)

	const shouldRedirectToOnboardingPage = useCallback(
		() => !isLoggedIn(),
		[isLoggedIn]
	)

	const renderHomePage = useCallback(
		() => {
			const shouldRedirect = shouldRedirectToOnboardingPage();

			if (shouldRedirect) {
				return (
					<Redirect
						to={{
							pathname: LOGIN_ROUTE,
							// Use Case 2. for example, URL is opened from email and user is NOT logged in
							// we need to save original 'from' pathname and after login we redirect to that 'from'
							state: { from: location.pathname } as RedirectToLoginState
						}}
					/>
				)
			} else {
				return <HomeRouter />
			}
		},
		[shouldRedirectToOnboardingPage, location.pathname]
	)

	return (
		<Switch>
			<Route path={LOGIN_ROUTE} component={OnboardingPage} />
			<Route render={renderHomePage} />
		</Switch>
	);
}
