// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columnContainer_container__7uELS > .columnContainer_item_disabled__JreYH:not(:empty) + .columnContainer_item_disabled__JreYH {
  margin-top: 0px !important;
}
.columnContainer_container__7uELS > .columnContainer_item_small__gh7Ej:not(:empty) + .columnContainer_item_small__gh7Ej {
  margin-top: 8px !important;
}
.columnContainer_container__7uELS > .columnContainer_item_medium__QzYY0:not(:empty) + .columnContainer_item_medium__QzYY0 {
  margin-top: 16px !important;
}
.columnContainer_container__7uELS > .columnContainer_item_large__aJmeT:not(:empty) + .columnContainer_item_large__aJmeT {
  margin-top: 20px !important;
}
.columnContainer_container__7uELS > .columnContainer_item_xlarge__OESnb:not(:empty) + .columnContainer_item_xlarge__OESnb {
  margin-top: 42px !important;
}
.columnContainer_container__7uELS.columnContainer_whiteBoxContainer__mNNok {
  background-color: var(--white-color);
  padding: 32px;
  border-radius: 5px;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);
  max-width: 840px;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/columnContainer.module.scss"],"names":[],"mappings":"AAEC;EACC,0BAAA;AADF;AAIC;EACC,0BAAA;AAFF;AAKC;EACC,2BAAA;AAHF;AAMC;EACC,2BAAA;AAJF;AAOC;EACC,2BAAA;AALF;AAQC;EACC,oCAAA;EACA,aAAA;EACA,kBAAA;EACA,8CAAA;EACA,gBAAA;EACA,SAAA;AANF","sourcesContent":[".container {\n\n\t& > .item_disabled:not(:empty) + .item_disabled {\n\t\tmargin-top: 0px !important;\n\t}\n\n\t& > .item_small:not(:empty) + .item_small {\n\t\tmargin-top: 8px !important;\n\t}\n\n\t& > .item_medium:not(:empty) + .item_medium {\n\t\tmargin-top: 16px !important;\n\t}\n\n\t& > .item_large:not(:empty) + .item_large {\n\t\tmargin-top: 20px !important;\n\t}\n\n\t& > .item_xlarge:not(:empty) + .item_xlarge {\n\t\tmargin-top: 42px !important;\n\t}\n\n\t&.whiteBoxContainer {\n\t\tbackground-color: var(--white-color);\n\t\tpadding: 32px;\n\t\tborder-radius: 5px;\n\t\tbox-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);\n\t\tmax-width: 840px;\n\t\tflex: 1\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `columnContainer_container__7uELS`,
	"item_disabled": `columnContainer_item_disabled__JreYH`,
	"item_small": `columnContainer_item_small__gh7Ej`,
	"item_medium": `columnContainer_item_medium__QzYY0`,
	"item_large": `columnContainer_item_large__aJmeT`,
	"item_xlarge": `columnContainer_item_xlarge__OESnb`,
	"whiteBoxContainer": `columnContainer_whiteBoxContainer__mNNok`
};
export default ___CSS_LOADER_EXPORT___;
