import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { ConfigurationModel } from './myNetworkFormModel';
import { ConfigurationMyNetworkForm } from './ConfigurationMyNetworkForm';
import { myNetworkRoute } from '../routes';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { getValidatorOrFullNodeItems } from 'feature/Network/helpers';
import { networkRoute } from 'containers/Network/routes';
import notifications from 'components/Notification/notification';

type ParamType = {
	networkId: string
}

export const ConfigurationMyNetwork = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newConfiguration: ConfigurationModel) => {
			if (!network) {
				return;
			}

			const validatorItems = getValidatorOrFullNodeItems(newConfiguration, 'validator');
			const fullNodeItems = getValidatorOrFullNodeItems(newConfiguration, 'fullnode');

			const configurationModel = new ConfigurationModel({
				...newConfiguration,
				validators: validatorItems,
				fullNodes: fullNodeItems,
				loadBalancers: [
					{id: 1, name: 'public-rpc-lb', loadBalancersTypeId: 1}
				]
			})

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel,
				isInitialized: true
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				notifications.info(`Well done 👏 You have successfully set up ${response.value.networkName} network. Also, we created public-rpc-ld Load Balancer for you.`);
				notifications.info('Before any further actions, we recommend configuring Polygon Edge first.');
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[history, network, dispatch]
	);

	const initialConfigurationMemo = useMemo(
		() => {
			if (network?.configuration) {
				return network.configuration;
			}

			const model = new ConfigurationModel();
			model.validatorsNumber = 4;
			model.fullNodesNumber = 2;
			model.validatorsDeviceCapacity = 16;
			model.fullNodesDeviceCapacity = 8;
			return model;
		},
		[network]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title="Let's get started" />
			<ConfigurationMyNetworkForm
				configNetwork={initialConfigurationMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
			/>
		</ColumnContainer>
	)
}
