import { Button } from 'components/Button'
import { ColumnContainer } from 'components/Layout'
import { ContentTitle } from 'feature/Content/ContentTitle'
import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { noop } from 'utils/commonHelper'
import { Item } from './Item'
import styles from './viewNetwork.module.scss'
import { MyNetworkSubRoutesEnum, myNetworkRoute } from 'containers/MyNetwork/routes'
import { createNetworkSubRoute, networkRoute } from '../routes'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

export const ViewNetwork = () => {
	const history = useHistory();

	const networks = useSelector((state: RootState) => state.networks);

	const onClickCallback = useCallback(
		(id: number) => {
			const network = networks.find(item => item.id === id);
			if (network?.isInitialized) {
				history.push(`${networkRoute.url}/${id.toString()}/${myNetworkRoute.url}`);
			} else {
				history.push(`${networkRoute.url}/${id.toString()}/${myNetworkRoute.url}/${MyNetworkSubRoutesEnum.CONFIGURATION}`);
			}
		},
		[history, networks]
	)

	return (
		networks.length > 0 ?
			<ColumnContainer>
				<ContentTitle title='All Networks' />
				<div className={styles.container}>
					{networks.map((network) => (
						<Item
							key={network.id}
							id={network.id!}
							name={network.details?.name || ''}
							regionId={network.details?.regionId!}
							onClick={onClickCallback}
						/>
					))}
				</div>
				<Link to={`${createNetworkSubRoute.url}`}>
					<Button
						text='Create Network'
						onClick={noop}
					/>
				</Link>
			</ColumnContainer>
			:
		<></>
	)
}
