import OnboardingRouter from './OnboardingRouter';
import styles from './onboarding.module.scss'

const OnboardingPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.img}></div>
            <div style={{ marginTop: '24px' }}>
                <OnboardingRouter />
            </div>
        </div>
    )
}

export default OnboardingPage;
