import { BootstrapAndServiceIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const bootstrapAndServiceRoute: Route = {
	id: RootRouteEnum.BOOTSTRAP_SERVICE,
	url: RootRouteEnum.BOOTSTRAP_SERVICE,
	text: 'Bootstrap & Service',
	Icon: BootstrapAndServiceIcon,
}