import { NetworkIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const networkRoute: Route = {
	id: RootRouteEnum.NETWORK,
	url: `/${RootRouteEnum.NETWORK}`,
	text: 'Network',
	Icon: NetworkIcon,
}

// subroutes
export enum NetworkSubRoutesEnum {
	CREATE = 'create',
	VIEW = 'view',
}

export const createNetworkSubRoute: Route = {
	id: NetworkSubRoutesEnum.CREATE,
	url: `/${RootRouteEnum.NETWORK}/${NetworkSubRoutesEnum.CREATE}`,
	text: 'Create',
}

export const viewNetworkSubRoute: Route = {
	id: NetworkSubRoutesEnum.VIEW,
	url: `/${RootRouteEnum.NETWORK}/${NetworkSubRoutesEnum.VIEW}`,
	text: 'View',
}
