// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileInput_input__L0EdS {
  appearance: none;
  border: 1px solid var(--field-border-color);
  border-radius: 4px;
  font-family: inherit;
  font-size: var(--font-size-control);
  color: var(--field-color);
  background: var(--field-background);
  padding: 6px 10px;
  height: 32px;
  width: 100%;
  cursor: pointer;
  height: unset;
  min-height: 32px;
}
.fileInput_input__L0EdS:focus, .fileInput_input__L0EdS:focus-within {
  border-color: var(--field-focus-border-color);
  outline: 0;
  box-shadow: none;
}
.fileInput_input__L0EdS:disabled {
  background: var(--field-disabled-background);
  color: var(--field-disabled-color);
  cursor: not-allowed;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/controls/FileInput/fileInput.module.scss","webpack://./src/components/Form/controls/control.mixins.scss"],"names":[],"mappings":"AAEA;ECkBC,gBAAA;EACA,2CArBgB;EAsBb,kBAAA;EACH,oBAAA;EACG,mCAAA;EACA,yBAAA;EACA,mCAAA;EACA,iBAAA;EACH,YA3BgB;EA4Bb,WAAA;EDzBH,eAAA;EACA,aAAA;EACA,gBCLgB;ADajB;ACiBC;EAlBG,6CAAA;EACA,UAAA;EACH,gBAAA;ADID;ACiBC;EAhCA,4CAAA;EACG,kCAAA;EACA,mBAAA;EACA,UAAA;ADkBJ","sourcesContent":["@import '../control.mixins.scss';\n\n.input {\n\t@include control();\n\tcursor: pointer;\n\theight: unset;\n\tmin-height: $control-height;\n}\n","$control-border: 1px solid var(--field-border-color);\n$control-height: 32px;\n\n@mixin disabled_control() {\n\tbackground: var(--field-disabled-background);\n    color: var(--field-disabled-color);\n    cursor: not-allowed;\n    opacity: 1;\n}\n\n@mixin focused_control() {\n\t// color: unset;\n    // background-color: unset;\n    border-color: var(--field-focus-border-color);\n    outline: 0;\n\tbox-shadow: none;\n}\n\n@mixin control() {\n\t// safari appearance for input and select is ugly\n\tappearance: none;\n\tborder: $control-border;\n    border-radius: 4px;\n\tfont-family: inherit;\n    font-size: var(--font-size-control);\n    color: var(--field-color);\n    background: var(--field-background);\n    padding: 6px 10px;\n\theight: $control-height;\n    width: 100%;\n\n\t&:focus,\n\t&:focus-within {\n\t\t@include focused_control();\n\t}\n\n\t&:disabled {\n\t\t@include disabled_control();\n\t}\n}\n\n@mixin dropdown() {\n\tposition: absolute;\n\tpadding: 4px;\n\tbackground: var(--field-dropdown-background);\n\tbox-shadow: 0 2px 8px rgba(0, 0, 0, 0.35);\n\tborder-radius: 4px;\n\tz-index: 1;\n\tdisplay: none;\n\n\t&.open {\n\t\tdisplay: block;\n\t}\n}\n\n@mixin explanation() {\n\tmargin: 0 0 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `fileInput_input__L0EdS`
};
export default ___CSS_LOADER_EXPORT___;
