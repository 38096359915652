import { FullNodesIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const fullNodesRoute: Route = {
	id: RootRouteEnum.FULL_NODES,
	url: RootRouteEnum.FULL_NODES,
	text: 'Full Nodes',
	Icon: FullNodesIcon,
}