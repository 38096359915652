import styles from './notificationComponent.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import './toastrOverride.scss';

type Props = {
	message: string;
};

const NotificationComponent = ({ message }: Props) => {
	return (
		<div className={styles.container}>
			<span className={styles.message}>{message}</span>
		</div>
	);
};

export default NotificationComponent;
