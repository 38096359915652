import { useCallback } from 'react';
import styles from './choose.module.scss';
import { NextIcon } from 'components/Icons/icons';
import { useHistory } from 'react-router-dom';
import { networkRoute } from 'containers/Network/routes';
import { ChooseType } from './Choose';

type Props = {
	item: ChooseType
	networkId: string
}

export const ChooseItem = ({ item, networkId }: Props) => {
	const history = useHistory();

	const onClickCallback = useCallback(
		() => {
			history.push(`${networkRoute.url}/${networkId}/${item.url}`);
		},
		[history, item.url, networkId]
	)

	return (
		<div className={styles.container} onClick={onClickCallback}>
			<div>
				{item.Icon && <item.Icon className={styles.icon} width={30} height={30} fill='currentColor' />}
				<span className={styles.text}>{item.text}</span>
			</div>
			<NextIcon width={20} height={20} fill='currentColor' />
		</div>
    )
}

