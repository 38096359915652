import path from 'path-browserify'
import { TabType } from './Tabs';
import styles from './tab.module.scss';
import { Link, useRouteMatch } from 'react-router-dom';

type Props = {
	tab: TabType
	isActive: boolean
}

export const Tab = ({ tab, isActive }: Props) => {
	const routematch = useRouteMatch();
	const className = `${styles.tab} ${isActive ? styles.active : ''} ${tab.disabled ? styles.disabled : ''}`;

	if (tab.disabled) {
		return (
			<div className={className}>
				{tab.title}
			</div>
		)
	}

	return (
		<Link
			className={className}
			to={path.join(routematch.url, tab.route)}
		>
			{tab.title}
		</Link>
	)
}
