import { Button } from 'components/Button'
import { useCallback, useMemo } from 'react'
import { RowContainer } from 'components/Layout'
import styles from './viewNetwork.module.scss'
import { regions } from '../CreateNetwork/NetworkForm'

type Props = {
	id: number
	regionId: number
	name: string
	onClick(id: number): void
}

export const Item = ({ id, regionId, name, onClick }: Props) => {
	const onClickCallback = useCallback(
		() => {
			onClick(id);
		},
		[onClick, id]
	)

	const regionName = useMemo(
		() => {
			const region = regions.find(item => item.id === regionId)
			return region && region.name
		},
		[regionId]
	)

	return (
		<div className={styles.content}>
			<RowContainer justifyContent='space-between'>
				<div>
					<p>Name</p>
					<h3>{name}</h3>
				</div>
				<div>
					<p>Region</p>
					<h3>{regionName}</h3>
				</div>
			</RowContainer>
			{/* because button has padding 12px 24px */}
			<div style={{ marginTop: '24px', marginLeft: '-24px', marginBottom: '-12px' }}>
				<Button
					text='View Network'
					color='neutral'
					onClick={onClickCallback}
				/>
			</div>
		</div>
	)
}
