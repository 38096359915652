import { ColumnContainer } from 'components/Layout'
import { useCallback, useMemo, useState } from 'react'
import { BoxItems } from './BoxItems'
import styles from './myNetwork.module.scss'
import { Button } from 'components/Button'
import { useHistory, useParams } from 'react-router-dom'
import { blockExplorersRoute, blockscoutBlockExplorersSubRoute, ethernalBlockExplorersSubRoute } from 'containers/BlockExplorers/routes'
import { loadBalancersRoute } from 'containers/LoadBalancers/routes'
import { EditSubRoutesEnum } from 'feature/Routes/RootRouteEnum'
import { useNetworkMemo } from 'feature/Network/networkHook'
import { ConfigurationModel, SimpleModel } from './ConfigurationMyNetwork/myNetworkFormModel'
import { NetworkModel } from 'feature/Network/NetworkModel'
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService'
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { useDispatch } from 'react-redux'
import { ConfirmModal } from './ConfirmModal/ConfirmModal'
import notifications from 'components/Notification/notification'

type ParamType = {
	networkId: string
}

export const MyNetwork = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);


	const openConfirmModal = useCallback(
		() => setIsConfirmModalOpen(true),
		[]
	);

	const closeConfirmModal = useCallback(
		() => setIsConfirmModalOpen(false),
		[]
	)

	const onLoadBalancersClickCallback = useCallback(
		(id: string) => {
			history.push(`${loadBalancersRoute.url}/${EditSubRoutesEnum.Edit}/${id}`);
		},
		[history]
	)

	const onBlockExplorersClickCallback = useCallback(
		(id: string) => {
			return id === '1' ?
				history.push(blockscoutBlockExplorersSubRoute.url) :
				history.push(ethernalBlockExplorersSubRoute.url);
		},
		[history]
	)

	const isDisabledMemo = useMemo(
		() => network && network.isBootstrapAndServiceInitialized ? false : true,
		[network]
	)

	const validatorsEditSaveCallback = useCallback(
		async (values: SimpleModel) => {
			if (!network) {
				return;
			}

			const newValidators = network.configuration?.validators?.map(item => {
				if (item.id === values.id) {
					return new SimpleModel({
						...item,
						stake: values.stake,
						stakeTypeId: values.stakeTypeId,
						premine: values.premine,
						premineTypeId: values.premineTypeId
					});
				}

				return item;
			});

			const configurationModel = new ConfigurationModel({
				...network.configuration,
				validators: newValidators
			})

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
			}
		},
		[dispatch, network]
	);

	const fullNodesEditSaveCallback = useCallback(
		async (values: SimpleModel) => {
			if (!network) {
				return;
			}

			const newFullNodes = network.configuration?.fullNodes?.map(item => {
				if (item.id === values.id) {
					return new SimpleModel({
						...item,
						premine: values.premine,
						premineTypeId: values.premineTypeId
					});
				}

				return item;
			});

			const configurationModel = new ConfigurationModel({
				...network.configuration,
				fullNodes: newFullNodes
			})

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
			}
		},
		[dispatch, network]
	);

	const validatorsContentMemo = useMemo(
		() => {
			return network && [network].map((item) => (
				<BoxItems
					key={item.id}
					items={item.configuration?.validators}
					isValidatorsOrFullNodes='validator'
					disabled={isDisabledMemo}
					onEditSave={validatorsEditSaveCallback}
				/>
			));
		},
		[network, isDisabledMemo, validatorsEditSaveCallback]
	)

	const fullNodesContentMemo = useMemo(
		() => {
			return network && [network].map((item) => (
				<BoxItems
					key={item.id}
					items={item.configuration?.fullNodes}
					isValidatorsOrFullNodes='fullnode'
					disabled={isDisabledMemo}
					onEditSave={fullNodesEditSaveCallback}
				/>
			));
		},
		[network, isDisabledMemo, fullNodesEditSaveCallback]
	)

	const loadBalancersContentMemo = useMemo(
		() => {
			return network && [network].map((item) => (
				<BoxItems
					key={item.id}
					items={item.configuration?.loadBalancers}
					onClick={onLoadBalancersClickCallback}
					disabled={isDisabledMemo}
				/>
			));
		},
		[network, onLoadBalancersClickCallback, isDisabledMemo]
	)

	const blockExplorerContentMemo = useMemo(
		() => {
			const blockExplorerItems: SimpleModel[] = [];

			if (network && network.blockscout) {
				blockExplorerItems.push({
					id: 1,
					name: network.blockscout.name || '',
					isBockscout: true
				});
			}

			if (network && network.ethernal) {
				blockExplorerItems.push({
					id: 2,
					name: network.ethernal.name || '',
					isEthernal: true
				});
			}

			return network && [network].map((item) => (
				<BoxItems
					key={item.id}
					items={blockExplorerItems}
					onClick={onBlockExplorersClickCallback}
				/>
			));
		},
		[network, onBlockExplorersClickCallback]
	)

	const handleCreateClickCallback = useCallback(
		() => history.push(blockExplorersRoute.url),
		[history]
	)

	const onConfirmedCallback = useCallback(
		async () => {
			if (!network) {
				return;
			}

			setIsDeploying(true);

			const networkModel = new NetworkModel({
				...network,
				isDeploy: true
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				notifications.success('Your blockchain is successfully deployed! 🛠️ We highly recommend testing your blockchain deployment.', {
					delay: 1500
				});
				notifications.info('You can see System Logs for each host. 📊 Also, you can see monitors for infrastructure and blockchain.', {
					delay: 1500
				});
				dispatch(setNetworkAction(networkModel));
			} else {
				notifications.warning(`Something's wrong? Try to recovery blockchain.`, {
					delay: 1500
				});
			}

			setTimeout(() => {
				setIsDeploying(false);
				closeConfirmModal();
			}, 1500);
		},
		[network, dispatch, closeConfirmModal]
	);

	return (
		<ColumnContainer>
			<div className={styles.container}>

				{validatorsContentMemo}

				{fullNodesContentMemo}

				{loadBalancersContentMemo}

				{(network && network.blockscout) || (network && network.ethernal) ?
					blockExplorerContentMemo
					:
					<div className={styles.content}>
						<div className={styles.create}>
							<div className={styles.text}>
								<span>You don't have any <br /> Block Explorer created.</span>
							</div>
							<Button
								text='Create'
								color='neutral'
								onClick={handleCreateClickCallback}
								disabled={isDisabledMemo}
							/>
						</div>
					</div>
				}

			</div>
			{network && network.isBlockExplorersInitialized && !network.isDeploy &&
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<Button
						text='Deploy'
						onClick={openConfirmModal}
					/>
				</div>
			}
			<ConfirmModal
				open={isConfirmModalOpen}
				title='Deploy network'
				message='Are you sure you want to deploy this network?'
				onConfirm={onConfirmedCallback}
				onCancel={closeConfirmModal}
				isDeploying={isDeploying}
			/>
		</ColumnContainer>
	)
}
