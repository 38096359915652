// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmModal_content__sVT8g {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmModal_content__sVT8g .confirmModal_message__qkbfU {
  padding: 24px 0;
}
.confirmModal_content__sVT8g img {
  padding-bottom: 16px;
}

.confirmModal_footer__Ps3fp {
  padding-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/containers/MyNetwork/ConfirmModal/confirmModal.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;AACD;AACC;EACC,eAAA;AACF;AAEC;EACC,oBAAA;AAAF;;AAIA;EACC,iBAAA;AADD","sourcesContent":[".content {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\n\t.message {\n\t\tpadding: 24px 0;\n\t}\n\n\timg {\n\t\tpadding-bottom: 16px;\n\t}\n}\n\n.footer {\n\tpadding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `confirmModal_content__sVT8g`,
	"message": `confirmModal_message__qkbfU`,
	"footer": `confirmModal_footer__Ps3fp`
};
export default ___CSS_LOADER_EXPORT___;
