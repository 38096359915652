import { useHistory } from 'react-router-dom';
import { Route } from 'feature/Routes/routeModel';
import styles from './menu.module.scss';
import { useCallback } from 'react';
import { networkRoute } from 'containers/Network/routes';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';

type Props = {
	route: Route
	networkId: string
    isActive?: boolean
	isAccountItem: boolean
}

export const Item = ({ route, networkId, isActive, isAccountItem }: Props) => {
	const history = useHistory();

	const onClickCallback = useCallback(
		() => {
			if (!networkId || route.url === `/${RootRouteEnum.NETWORK}`) {
				return history.push(`${networkRoute.url}`);
			}

			history.push(`${networkRoute.url}/${networkId}/${route.url}`);
		},
		[history, networkId, route]
	)

	const iconWidthHeight = route.id === RootRouteEnum.NETWORK ? 30 : 20;
	const className = `${styles.item} ${isAccountItem ? styles.last_item : ''} ${isActive ? styles.active : ''}`;

	return (
		<div className={className} onClick={onClickCallback}>
			<span>
				{route.Icon && <route.Icon className={styles.icon} width={iconWidthHeight} height={iconWidthHeight} fill='currentColor' />}
			</span>
		</div>
    )
}

