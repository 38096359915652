import { ColumnContainer } from 'components/Layout'
import { ContentTitle } from 'feature/Content/ContentTitle'
import { useCallback, useMemo } from 'react'
import { ConfigurationModel, SimpleModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel'
import { useHistory, useParams } from 'react-router-dom'
import { BoxItems } from 'containers/MyNetwork/BoxItems'
import { FullNodesForm } from './FullNodesForm'
import { useNetworkMemo } from 'feature/Network/networkHook'
import { NetworkModel } from 'feature/Network/NetworkModel'
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService'
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { useDispatch } from 'react-redux'
import { updateValidatorOrFullNodeItems } from 'feature/Network/helpers'
import { networkRoute } from 'containers/Network/routes'
import { myNetworkRoute } from 'containers/MyNetwork/routes'

type ParamType = {
	networkId: string
}

export const FullNodes = () => {
	const dispatch = useDispatch();
	const { networkId }: ParamType = useParams();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newConfigFullNodes: ConfigurationModel) => {
			if (!network) {
				return;
			}

			const hasChanged = network.configuration !== newConfigFullNodes;
			const fullNodesItems = updateValidatorOrFullNodeItems(network.configuration!, newConfigFullNodes, 'fullnode');

			const configurationModel = new ConfigurationModel({
				...newConfigFullNodes,
				fullNodes: hasChanged ? fullNodesItems : network.configuration?.fullNodes,
			})

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel,
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[history, dispatch, network]
	);

	const editSaveCallback = useCallback(
		async (values: SimpleModel) => {
			if (!network) {
				return;
			}

			const newFullNodes = network.configuration?.fullNodes?.map(item => {
				if (item.id === values.id) {
					return new SimpleModel({
						...item,
						premine: values.premine,
						premineTypeId: values.premineTypeId
					});
				}

				return item;
			});

			const configurationModel = new ConfigurationModel({
				...network.configuration,
				fullNodes: newFullNodes
			})

			const networkModel = new NetworkModel({
				...network,
				configuration: configurationModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
			}
		},
		[dispatch, network]
	);

	const fullNodesContentMemo = useMemo(
		() => {
			return network && [network].map((item) => (
				<BoxItems
					key={item.id}
					items={item.configuration?.fullNodes}
					hideViewAllButton
					isValidatorsOrFullNodes='fullnode'
					onEditSave={editSaveCallback}
				/>
			));
		},
		[network, editSaveCallback]
	)

	const initialConfigFullNodes = useMemo(
		() => {
			if (network?.configuration) {
				return network.configuration;
			}
		},
		[network?.configuration]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Full Nodes' />
				<FullNodesForm
					configFullNodes={initialConfigFullNodes}
					onSave={handleSaveCallback}
					cancel={handleGoBackCallback}
					fullNodesContent={fullNodesContentMemo}
				/>
		</ColumnContainer>
	)
}
