import { Subtract } from 'utility-types';
import { FileInput, FileInputProps, InsertAttachmentRequest } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const FileInputField = (props: Subtract<FileInputProps, ControlsCommonProps<InsertAttachmentRequest[]>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={FileInput}
		/>
	)
}
