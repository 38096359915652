import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum UserRoleEnum {
	Standard = 1,
}

export interface RoleResponseModel {
	id: number;
	name: string;
	type: UserRoleEnum;
}

export interface UserResponseModel {
	id: number;
	email: string;
	createdAt: Date;
	roles: RoleResponseModel[];
}

export interface UserDataState {
	user: UserResponseModel | undefined,
}

const initialState: UserDataState = {
	user: undefined,
}

const userDataSlice = createSlice({
	name: 'userData',
	initialState,
	reducers: {
		setUserDataSliceAction: (state, action: PayloadAction<UserResponseModel>) => {
			state.user = action.payload
		},
		removeUserDataSliceAction: (state) => {
			state.user = undefined
		},
	},
})

// Action creators are generated for each case reducer function
export const { setUserDataSliceAction, removeUserDataSliceAction } = userDataSlice.actions

export default userDataSlice.reducer
