import { NETWORKS_SET, NETWORK_SET, NETWORK_CREATE, SetNetworksActionType, CreateNetworkActionType, SetNetworkActionType } from "./actions";
import { NetworkModel } from "feature/Network/NetworkModel";

const initialState: NetworkModel[] = [];

type ReducerActionType = SetNetworksActionType | SetNetworkActionType | CreateNetworkActionType

export const networksReducer = (state = initialState, action: ReducerActionType) => {
	switch (action.type) {
		case NETWORKS_SET:
			return (action as SetNetworksActionType).networks;
		case NETWORK_SET:
			return state.map((item) => {
				if (item.id !== (action as SetNetworkActionType).network.id) {
					return item;
				}

				return (action as SetNetworkActionType).network;
			});
		case NETWORK_CREATE:
			return [...state, (action as CreateNetworkActionType).network];
		default:
			return state;
	}
};
