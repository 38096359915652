// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldMessage_container__DLypL {
  margin: 0;
  padding-top: 1px;
  font-size: var(--font-size-small);
}

.fieldMessage_error__IKnEA {
  color: var(--field-error);
}

.fieldMessage_warning__Ca9qU {
  color: var(--field-warning);
}`, "",{"version":3,"sources":["webpack://./src/components/Form/addons/FieldMessage/fieldMessage.module.scss"],"names":[],"mappings":"AAAA;EACC,SAAA;EACA,gBAAA;EACA,iCAAA;AACD;;AAEA;EACC,yBAAA;AACD;;AAEA;EACC,2BAAA;AACD","sourcesContent":[".container {\n\tmargin: 0;\n\tpadding-top: 1px;\n\tfont-size: var(--font-size-small);\n}\n\n.error {\n\tcolor: var(--field-error);\n}\n\n.warning {\n\tcolor: var(--field-warning);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fieldMessage_container__DLypL`,
	"error": `fieldMessage_error__IKnEA`,
	"warning": `fieldMessage_warning__Ca9qU`
};
export default ___CSS_LOADER_EXPORT___;
