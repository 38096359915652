import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { AppContainer } from './containers/AppContainer';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
	<React.StrictMode>
		<AppContainer />
		<ToastContainer />
	</React.StrictMode>,
	document.getElementById('root')
);
