export class ServiceModel {
	maxSlots!: number;
	maxEnqueued!: number;
	epochSize!: number;
	blockGasLimit!: number;
	blockTime!: number;
	gasPrice!: number;

	constructor(data?: ServiceModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
