import { ValidatorsIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const validatorsRoute: Route = {
	id: RootRouteEnum.VALIDATORS,
	url: RootRouteEnum.VALIDATORS,
	text: 'Validators',
	Icon: ValidatorsIcon,
}