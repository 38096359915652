export class DetailsModel {
	domain!: string;
	name!: string;
	cloudServiceId!: number;
	regionId!: number;

	constructor(data?: DetailsModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
