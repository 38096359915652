export class AppSettings {
    private _apiUrl: string = 'https://deployment-api.testnet.ethernal.work';

    public constructor() {
        const settingsJson = require(process.env.NODE_ENV === "development" ? './appSettings_development.json' : './appSettings_production.json');
        this._apiUrl = settingsJson.apiUrl;
    }

    public getApiUrl(): string {
        return this._apiUrl;
    }
}

const appSettings = new AppSettings();
export default appSettings;
