import { useCallback, useMemo } from 'react';
import { ColumnContainer } from 'components/Layout';
import { ContentTitle } from 'feature/Content/ContentTitle';
import { useHistory, useParams } from 'react-router-dom';
import { AdvancedConfigurationForm } from './AdvancedConfigurationForm';
import { AdvancedConfigurationModel, PolygonEdgeModel } from '../polygonEdgeFormModel';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { useDispatch } from 'react-redux';

type ParamType = {
	networkId: string
}

export const AdvancedConfiguration = () => {
	const { networkId }: ParamType = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newConfiguration: AdvancedConfigurationModel) => {
			if (!network?.polygonEdge) {
				return;
			}

			const polygonEdgeModel = new PolygonEdgeModel({
				...network.polygonEdge,
				advancedConfiguration: newConfiguration
			});

			const networkModel = new NetworkModel({
				...network,
				polygonEdge: polygonEdgeModel
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				handleGoBackCallback();
			}
		},
		[network, handleGoBackCallback, dispatch]
	);

	const advancedConfigurationMemo = useMemo(
		() => {
			if (network?.polygonEdge?.advancedConfiguration) {
				return network.polygonEdge.advancedConfiguration;
			}

			const model = new AdvancedConfigurationModel();
			model.directory = '/var/lib/edge';
			model.deviceName = 'nvme1n1';
			model.location = '/dev/nvme1n1';
			model.mountPoint = '/var/lib/edge';
			model.partitionName = 'State Data'
			return model;
		},
		[network?.polygonEdge]
	)

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Polygon Edge' subtitle='Advanced Configuration' />
			<AdvancedConfigurationForm
				configuration={advancedConfigurationMemo}
				onSave={handleSaveCallback}
				cancel={handleGoBackCallback}
			/>
		</ColumnContainer>
	)
}
