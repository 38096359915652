import { CustomContractsIcons } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const customContractsRoute: Route = {
	id: RootRouteEnum.CUSTOM_CONTRACTS,
	url: RootRouteEnum.CUSTOM_CONTRACTS,
	text: 'Custom Contracts',
	Icon: CustomContractsIcons,
}