// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.separator_vertical_xsmall__sSd05 {
  height: 4px;
}

.separator_vertical_small__eNlnE {
  height: 8px;
}

.separator_vertical_medium__O4yWE {
  height: 16px;
}

.separator_vertical_large__WVMKp {
  height: 32px;
}

.separator_vertical_xlarge__\\+REVQ {
  height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Separator/separator.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;;AAEA;EACC,WAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,YAAA;AACD","sourcesContent":[".vertical_xsmall {\n\theight: 4px;\n}\n\n.vertical_small {\n\theight: 8px;\n}\n\n.vertical_medium {\n\theight: 16px;\n}\n\n.vertical_large {\n\theight: 32px;\n}\n\n.vertical_xlarge {\n\theight: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical_xsmall": `separator_vertical_xsmall__sSd05`,
	"vertical_small": `separator_vertical_small__eNlnE`,
	"vertical_medium": `separator_vertical_medium__O4yWE`,
	"vertical_large": `separator_vertical_large__WVMKp`,
	"vertical_xlarge": `separator_vertical_xlarge__+REVQ`
};
export default ___CSS_LOADER_EXPORT___;
