import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { MyNetwork } from './MyNetwork'
import { MyNetworkSubRoutesEnum } from './routes'
import { ConfigurationMyNetwork } from './ConfigurationMyNetwork/ConfigurationMyNetwork'

export const MyNetworkRouter = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${MyNetworkSubRoutesEnum.CONFIGURATION}`}
				render={
					() => <ConfigurationMyNetwork />
				}
			/>

			<Route
				render={
					() => <MyNetwork />
				}
			/>
		</Switch>
	)
}
