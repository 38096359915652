import { useHistory, useParams } from 'react-router-dom';
import { ServiceForm } from './ServiceForm';
import { ServiceModel } from './serviceFormModel';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNetworkMemo } from 'feature/Network/networkHook';
import { NetworkModel } from 'feature/Network/NetworkModel';
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService';
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { networkRoute } from 'containers/Network/routes';
import { myNetworkRoute } from 'containers/MyNetwork/routes';
import notifications from 'components/Notification/notification';

type ParamType = {
	networkId: string
}

export const Service = () => {
	const { networkId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newService: ServiceModel) => {
			if (!network) {
				return;
			}

			const networkModel = new NetworkModel({
				...network,
				service: newService,
				isBootstrapAndServiceInitialized: true
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				notifications.warning(`Don't forget to configure Stake and Balance on Nodes. Also, you may want to add some premined balance to your account or some load-testing account.`);
				notifications.warning('If you want to add custom contracts you can do it in the Custom Contract section.');
				notifications.info('Congratulations 🎉 Your blockchain is successfully configured! Do you want to see how blocks are mined? Create beautiful Block Explorer 💎.');
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[network, dispatch, history]
	);

	const initialServiceMemo = useMemo(
		() => {
			if (network?.service) {
				return network.service;
			}

			const model = new ServiceModel();
			model.maxSlots = 276480;
			model.maxEnqueued = 276480;
			model.epochSize = 10;
			model.blockGasLimit = 200000000;
			model.blockTime = 1;
			model.gasPrice = 100000000;
			return model
		},
		[network]
	)

	return (
		<ServiceForm
			service={initialServiceMemo}
			onSave={handleSaveCallback}
			cancel={handleGoBackCallback}
		/>
	)
}
