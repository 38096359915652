import { CheckboxField, Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { EthernalBlockExplorerModel } from '../ethernalBlockExplorerFormModel';

type Props = {
	configEthernal?: EthernalBlockExplorerModel
	onSave: (newConfigEthernal: EthernalBlockExplorerModel) => void
	cancel(): void
}

export const ConfigurationStepTwoForm = ({ configEthernal, onSave, cancel }: Props) => {
	const [values, setValues] = useState(configEthernal || new EthernalBlockExplorerModel());

	const onSubmitCallback = useCallback(
		async () => {
			await onSave(values);
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Next'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartContainer>
								<SmartItem size='small'>
									<SmartFormGroup label='Synchronization Parameters'>
										<InputNumberField
											id={propertyOf<EthernalBlockExplorerModel>('numberOfWorkers')}
											label='Number Of Workers'
											isRequired
										/>
										<InputNumberField
											id={propertyOf<EthernalBlockExplorerModel>('checkpoint')}
											label='Checkpoint'
											isRequired
										/>
										<InputNumberField
											id={propertyOf<EthernalBlockExplorerModel>('checkpointDistance')}
											label='Checkpoint Distance'
											isRequired
										/>
										<InputField
											id={propertyOf<EthernalBlockExplorerModel>('gatewayURL')}
											label='IPFS Gateway URL'
											isRequired
										/>
									</SmartFormGroup>
								</SmartItem>
								<SmartItem size='small'>
									<VerticalSeparator />
									<InputNumberField
										id={propertyOf<EthernalBlockExplorerModel>('step')}
										label='Step'
										isRequired
									/>
									<InputNumberField
										id={propertyOf<EthernalBlockExplorerModel>('checkpointWindow')}
										label='Checkpoint Window'
										isRequired
									/>
									<InputNumberField
										id={propertyOf<EthernalBlockExplorerModel>('callTimeout')}
										label='Call Timeout (seconds)'
										isRequired
									/>
									<VerticalSeparator />
									<SmartInline>
										<CheckboxField
											id={propertyOf<EthernalBlockExplorerModel>('includeLOGs')}
											labelBefore='Include LOGs'
										/>
										<CheckboxField
											id={propertyOf<EthernalBlockExplorerModel>('includeNFTs')}
											labelBefore='Include NFTs'
										/>
									</SmartInline>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

