export enum RootRouteEnum {
	NETWORK = 'network',
	MYNETWORK = 'my_network',
	POLYGON_EDGE = 'polygon_edge',
	PROFILE = 'profile',
	BOOTSTRAP_SERVICE = 'bootstrap_service',
	VALIDATORS = 'validators',
	FULL_NODES = 'full_nodes',
	LOAD_BALANCERS = 'load_balancers',
	BLOCK_EXPLORERS = 'block_explorers',
	ADDITIONAL_ACCOUNTS = 'additional_accounts',
	CUSTOM_CONTRACTS = 'custom_contracts'
}

export enum EditSubRoutesEnum {
	Edit = 'edit',
}
