import { AutoCompleteField, Form, InputField, InputNumberField, } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import { useCallback, useState } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { SimpleModel } from 'containers/MyNetwork/ConfigurationMyNetwork/myNetworkFormModel';
import { SmartInline } from 'components/SmartContainer/SmartContainer';
import { InstanceItemType, instanceClass, instanceType } from 'data/awsData';

type Props = {
	configuration: SimpleModel
	cancel(): void
	onSave?(values: SimpleModel): Promise<void>
	isValidatorsOrFullNodes?: 'validator' | 'fullnode'
}

export const EditForm = ({ configuration, cancel, onSave, isValidatorsOrFullNodes }: Props) => {
	const [values, setValues] = useState(configuration || new SimpleModel());

	const onSubmitCallback = useCallback(
		async () => {
			onSave && await onSave(values);
			cancel();
		},
		[values, onSave, cancel]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			submitButtonText='Save'
			submitButtonColor='primary'
			render={() => (
				<ColumnContainer>
					<InputField
						id={propertyOf<SimpleModel>('name')}
						label='Name'
						disabled
					/>
					<AutoCompleteField
						id={propertyOf<SimpleModel>('instanceClassId')}
						label='Instance Class'
						items={instanceClass}
						getItemId={(item: InstanceItemType) => item.id}
						getItemText={(item: InstanceItemType) => item.name}
						disabled
					/>
					<AutoCompleteField
						id={propertyOf<SimpleModel>('instanceTypeId')}
						label='Instance Type'
						items={instanceType}
						getItemId={(item: InstanceItemType) => item.id}
						getItemText={(item: InstanceItemType) => `${item.name} (${item.description})`}
						disabled
					/>
					{isValidatorsOrFullNodes === 'validator' &&
						<SmartInline>
							<InputNumberField
								id={propertyOf<SimpleModel>('stake')}
								label='Stake'
							/>
							<AutoCompleteField
								id={propertyOf<SimpleModel>('stakeTypeId')}
								label='Type'
								items={[{id: 1, name: 'WEI'}, {id: 2, name: 'TIE'}]}
								getItemId={(item: any) => item.id}
								getItemText={(item: any) => item.name}
							/>
						</SmartInline>
					}
					<SmartInline>
						<InputNumberField
							id={propertyOf<SimpleModel>('premine')}
							label='Premine'
						/>
						<AutoCompleteField
							id={propertyOf<SimpleModel>('premineTypeId')}
							label='Type'
							items={[{id: 1, name: 'WEI'}, {id: 2, name: 'TIE'}]}
							getItemId={(item: any) => item.id}
							getItemText={(item: any) => item.name}
						/>
					</SmartInline>
				</ColumnContainer>
			)}
		/>
	)
}
