import { BlockExplorersIcons, BlockscoutIcon, EthernalBlockIcon } from 'components/Icons/icons';
import { RootRouteEnum } from 'feature/Routes/RootRouteEnum';
import { Route } from 'feature/Routes/routeModel';

// root route
export const blockExplorersRoute: Route = {
	id: RootRouteEnum.BLOCK_EXPLORERS,
	url: RootRouteEnum.BLOCK_EXPLORERS,
	text: 'Block Explorers',
	Icon: BlockExplorersIcons,
}

// subroutes
export enum BlockExplorersSubRoutesEnum {
	BLOCKSCOUT = 'blockscout',
	ETHERNAL_BLOCK_EXPLORER = 'ethernal_block_explorer',
}

export const blockscoutBlockExplorersSubRoute: Route = {
	id: BlockExplorersSubRoutesEnum.BLOCKSCOUT,
	url: `${RootRouteEnum.BLOCK_EXPLORERS}/${BlockExplorersSubRoutesEnum.BLOCKSCOUT}`,
	text: 'Blockscout',
	Icon: BlockscoutIcon
}

export const ethernalBlockExplorersSubRoute: Route = {
	id: BlockExplorersSubRoutesEnum.ETHERNAL_BLOCK_EXPLORER,
	url: `${RootRouteEnum.BLOCK_EXPLORERS}/${BlockExplorersSubRoutesEnum.ETHERNAL_BLOCK_EXPLORER}`,
	text: 'Ethernal Block Explorer',
	Icon: EthernalBlockIcon
}

export enum StepSubRoutesEnum {
	STEP_ONE = 'step_1',
	STEP_TWO = 'step_2',
	STEP_THREE = 'step_3'
}

export enum BlockscoutSubRoutesEnum {
	ADVANCED_CONFIGURATION = 'advanced_configuration',
}
