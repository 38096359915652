import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ColumnContainer } from 'components/Layout'
import { ContentTitle } from 'feature/Content/ContentTitle'
import { CustomContractsModel } from './customContractsFormModel'
import { CustomContractsForm } from './CustomContractsForm'
import { useDispatch } from 'react-redux'
import { useNetworkMemo } from 'feature/Network/networkHook'
import { NetworkModel } from 'feature/Network/NetworkModel'
import { SaveNetworkConfigurationRequest } from 'services/bladeApiService'
import { saveNetworkConfigurationAction, setNetworkAction } from 'feature/Network/actions'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { networkRoute } from 'containers/Network/routes'
import { myNetworkRoute } from 'containers/MyNetwork/routes'

type ParamType = {
	networkId: string
}

export const CustomContracts = () => {
	const { networkId }: ParamType = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const network = useNetworkMemo(parseInt(networkId));

	const handleGoBackCallback = useCallback(
		() => {
			history.goBack();
		},
		[history]
	)

	const handleSaveCallback = useCallback(
		async (newContracts: CustomContractsModel[]) => {
			if (!network || network.customContracts?.length === newContracts.length) {
				return;
			}

			const networkModel = new NetworkModel({
				...network,
				customContracts: newContracts
			});

			const requestModel = new SaveNetworkConfigurationRequest({
				id: network.id,
				networkName: network.details?.name || '',
				networkConfiguration: JSON.stringify(networkModel)
			});

			const bindedAction = saveNetworkConfigurationAction.bind(null, requestModel);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				dispatch(setNetworkAction(networkModel));
				history.push(`${networkRoute.url}/${response.value.id.toString()}/${myNetworkRoute.url}`);
			}
		},
		[network, history, dispatch]
	);

	return (
		<ColumnContainer whiteBoxContainer>
			<ContentTitle title='Custom Contracts' />
				<CustomContractsForm
					contractItems={network?.customContracts}
					onSave={handleSaveCallback}
					cancel={handleGoBackCallback}
				/>
		</ColumnContainer>
	)
}
