export class AccountModel {
	username!: string;
	email!: string;
	company?: string | undefined;
	awsKeyId?: string | undefined;
	secretAccessAwsKey?: string | undefined;
	dataDogKeyId?: string | undefined;
	dataDogApiKey?: string | undefined;

	constructor(data?: AccountModel) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property))
					(this as any)[property] = (data as any)[property];
			}
		}
	}
}
